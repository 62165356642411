import React, { useEffect, useState, Fragment } from 'react';
import { Badge, Button, ButtonGroup, Card, Modal, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams, useRef } from 'react-router-dom';
import videojs from 'video.js';
import Draggable from 'react-draggable';

import HttpRequest from './../../Common/httpServices/http.service';
import UploadFile from '../common-file-upload/UploadFile';
import ImageForm from '../forms/image-form/imageForm';
import HotspotForm from '../forms/hotspot-form/hotspotForm';
import TextForm from '../forms/text-form/textForm';
import Helper from '../../Common/helper/helper';
import { isSessionExpire } from '../authentication/jwt/manageSession';

import "./player.css";
import "../../style.css";
import { deleteHotspot, deleteImage, deleteText, manageSeekbarIcon, getTemplateById, removeSeekbarIcons, deleteForm, getVideoTemplateList, removeInteraction, getImageById, getFormQuizById } from "./player-methods/playerMethods"
import Loader from '../../Common/loader/loder';
import CtaSettings from '../forms/cta-settings/ctaSettings';
import Template from '../forms/template/template';
import ModifyTemplate from '../forms/modify-template/modifyTemplate';
import axios from 'axios';
import VideoBranchingForm from '../forms/video-branching-form/videoBranchingForm';
import VideoCloning from '../cloning/video-cloning/videoCloning';
import VideoCloneList from '../cloning/video-clone-listing/videoCloneList';

//
var idTimeOut = null;

const Player = () => {
    /* states */
    const [playerVideoInfo, setPlayerVideoInfo] = useState("");
    const [player, setPlayer] = useState("");
    const [contentMedia, setContentMedia] = useState([]);
    const [contentMediaSecHide, setContentMediaSecHide] = useState(false);
    const [playerCurrentTime, setPlayerCurrentTime] = useState(0);
    const [contentMediaId, setContentMediaId] = useState();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isToggalMode, setIsToggalMode] = useState(false);
    const [isPlayerRunningMode, setIsPlayerRunningMode] = useState(false);
    const [dragMode, setDragMode] = useState(false);
    const [pauseSecMode, setPauseSecMode] = useState(false);
    const [contentMediaObjectEditMode, setContentMediaObjectEditMode] = useState("");
    // click action
    const [clickActionEnable, setClickActionEnable] = useState(true);
    const [showMsgEnable, setShowMsgEnable] = useState(false);
    const [action, setAction] = useState("");

    // const [imageUpload, setImageUpload] = useState(false);

    // const 
    const [mainVideoId, setMainVideoId] = useState(null);

    let [activeDrags, setActiveDrags] = useState(0);
    let [deltaPosition, setDeltaPosition] = useState({
        x: 0,
        y: 0
    });

    // modal states
    const [uploadImgModal, setUploadImgModal] = useState(false);
    // states for upload-files
    const [uploadType, setUploadType] = useState("");
    const [branchingMapping, setBranchingMapping] = useState();
    const [branchingDraggableProps, setBranchingDraggableProps] = useState({});
    const [branchingMappingShowHide, setBranchingMappingShowHide] = useState(true);
    const [returnBtnVisible, setReturnBtnVisible] = useState(false);
    const [totalDuration, setTotalDuration] = useState(0);
    const [loader, setLoader] = useState(false);
    const [templateVisible, setTemplateVisible] = useState(false);
    const [templateContent, setTemplateContent] = useState("");

    const [fixedTemplateVisible, setFixedTemplateVisible] = useState(false);
    const [fixedTemplateContent, setFixedTemplateContent] = useState("");
    const [defualtDragPosition, setDefualtDragPosition] = useState();
    const [defualtDragPositionBranching, setDefualtDragPositionBranching] = useState();
    const [draggableProps, setDraggableProps] = useState();

    // fixedTemplateContent
    // quiz rendring
    const [landingPageObj, setLandingPageObj] = useState(false);
    const [landingPageObjVisible, setLandingPageObjVisible] = useState(false);
    const [quizIndexObj, setQuizIndexObj] = useState({});
    const [contentMediaNew, setContentMediaNew] = useState([]);
    const [videoRenderType, setVideoRenderType] = useState(false);

    // testing state
    const [publisherData, setPublisherData] = useState(null);

    // const variables
    const location = useLocation();
    const navigate = useNavigate();

    // When Back button click
    window.onpopstate = () => { window.location.reload(true); }

    const { main_video_id } = useParams()
    let check = main_video_id.includes("&");
    var video_id = null;
    let paramArray = [];

    if (check) {
        paramArray = main_video_id.split("&");
        video_id = paramArray[0];
    } else {
        video_id = main_video_id;
    }

    // draggable 
    let onStart = () => {
        setActiveDrags(++activeDrags)
    };
    let onStop = () => {
        setActiveDrags(--activeDrags)
    };
    const dragHandlers = { onStart: onStart, onStop: onStop };

    const handleDrag = (e, ui) => {
        const contentId = ui.node.id;
        // setDeltaPosition to states
        setDragMode(true);
        setDeltaPosition({
            x: ui.lastX,
            y: ui.lastY
        })
        // console.log(ui.lastX,ui.lastY);
        const draggableChildDiv = document.getElementById(contentId);
        const floatingDivImg = document.getElementById(`floating_${contentId}`);

        // get and set overfloat div hight and width it's parent div
        if (uploadType === "image") {
            floatingDivImg.style.width = (draggableChildDiv.offsetWidth - 15) + "px";
            floatingDivImg.style.height = (draggableChildDiv.offsetHeight - 15) + "px";
        }
    };

    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);


    const uploadImgToggle = async (uploadType, localPlayer) => {
        // Fixed position
        sessionStorage.setItem("pauseVideoSecongsSession", "true")
        if (sessionStorage.getItem("timerId")) {
            sessionStorage.getItem("timerId").split(",").map((timerId) => {
                clearTimeout(timerId);
            });
            sessionStorage.removeItem('timerId');
            setFixedTemplateVisible(true);

        }

        if (uploadType !== "image")
            setIsToggalMode(true);

        if (localPlayer === undefined) {
            await player.pause();
        } else {
            localPlayer.pause();
        }

        if (uploadType === "hotspot" || uploadType === "TXT" || uploadType === "cta_settings" || uploadType === "branching") {
            setContentMediaSecHide(true); // hide/show form
            try {
                setShowMsgEnable(false);
                removeInteraction();
            } catch (e) { }

            if (uploadType === "cta_settings" || uploadType === "TXT" || uploadType === "hotspot" || uploadType === "branching") {
                player.play();
                player.currentTime(0);
                player.pause();
                player.controls(true);
                document.getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none'; // desable seekbar when add any interaction

                // setFixedTemplateVisible(false)
                setIsEditMode(true);
                setBranchingMappingShowHide(false);
            }

            if (uploadType === 'TXT') {
                setContentMediaNew([{ id: 'BLANK', videoId: video_id, label: "label", textContent: "<p>Enter text in editor</p>", startTime: '', endTime: '', isPauseEnable: 0, pauseLength: 0, isLandingPage: 0, isClickRequired: 0, actionType: 0, actionContent: "", interactionId: 0, draggableProps: "{\"height\":\"auto\",\"width\":\"auto\",\"cancel\":\"\",\"grid\":[1,1],\"defaultPosition\":{\"x\":30,\"y\":10}}", status: 0, useAsBtn: 0, mediaType: "TXT" }])
                setContentMediaId('BLANK');
                setDeltaPosition({ x: 30, y: 10 });
            } else if (uploadType === 'hotspot') {
                setContentMediaNew([{ id: 'BLANK', videoId: video_id, label: "label", hotspotType: 1, hotspotIconPath: "/assets/icons/plus_icon.png", startTime: '', endTime: '', isPauseEnable: 0, pauseLength: 0, isLandingPage: 0, isClickRequired: 0, actionType: 5, actionContent: "", interactionId: 0, draggableProps: "{\"cancel\":\"\",\"grid\":[1,1],\"defaultPosition\":{\"x\":30,\"y\":10}}", mediaType: "hotspot" }])
                setContentMediaId('BLANK');
                setDeltaPosition({ x: 30, y: 10 });
            }
        }

        if (uploadType === "template") {
            setContentMediaSecHide(true);
        }

        if (uploadType === "image" || uploadType === "") {
            // setUploadImgModal(!uploadImgModal);
            setShow(!show);// show model
            setContentMediaSecHide(false);
        }

        if (uploadType !== undefined || uploadType !== null) {
            setUploadType(uploadType);
        }
    }

    const edit = async (contentObj, uploadType, initial) => {
        // fixed place don't change row position
        setIsEditMode(true);
        sessionStorage.setItem("pauseVideoSecongsSession", "true");
        sessionStorage.setItem("uploadType", uploadType);
        sessionStorage.setItem("contentId", contentObj.id);

        setShowMsgEnable(false);
        setFixedTemplateVisible(true);

        document.getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none';
        if (sessionStorage.getItem("timerId")) {
            sessionStorage.getItem("timerId").split(",").map((timerId) => {
                clearTimeout(timerId);
            });
            //    clearTimeout(sessionStorage.getItem("timerId")); 
            sessionStorage.removeItem('timerId');
        }

        if (initial) {
            let contentObjNew = { ...contentObj };
            const draggableProps = JSON.parse(contentObjNew.draggableProps);
            if (draggableProps.cancel === "div") {
                // set and chnage content media JSON
                draggableProps.cancel = "";
                contentObjNew.draggableProps = JSON.stringify(draggableProps);
            }
            setContentMediaNew([contentObjNew]);
            // await player.currentTime((currentTime - 1));
            await player.play();
            await player.pause();
            player.controls(false);
        }

        let contentId = contentObj.id;

        setContentMediaSecHide(!contentMediaSecHide);

        setUploadType(uploadType);
        if (contentId) {
            setContentMediaId(contentId);
        }

        const contentMediaInfo = contentObj;
        const renderSpecificCM = document.getElementById(`div_${contentObj.mediaType}_${contentId}`);
        // const specificFloatingDiv = document.getElementById(`floating_div${contentId}`);
        const draggableProps = JSON.parse(contentMediaInfo.draggableProps);

        // set position
        draggableProps && setDeltaPosition(draggableProps.defaultPosition)

        const renderAllCM = document.getElementsByClassName("draggable_child_div");
        console.log(contentMediaInfo.width);

        for (let i = 0; i < renderAllCM.length; i++) {
            if (String(renderAllCM[i].id) === String(renderSpecificCM.id)) {
                if (draggableProps.cancel === "div") {
                    // set and chnage content media JSON
                    draggableProps.cancel = "";
                    contentMediaInfo.draggableProps = JSON.stringify(draggableProps);

                    // temp changes into content media
                    renderSpecificCM.style.transform = `translate(${draggableProps.defaultPosition.x}px, ${draggableProps.defaultPosition.y}px)`;
                    renderSpecificCM.style.overflow = "auto";
                    renderSpecificCM.style.display = "block";

                    if (uploadType === "hotspot") {
                        renderSpecificCM.style.overflow = "unset";
                    }

                    if (uploadType !== "hotspot" && uploadType !== "TXT") {
                        // renderSpecificCM.style.resize = "none";
                        renderSpecificCM.style.resize = "horizontal";
                    }

                    if (uploadType === "TXT") {
                        renderSpecificCM.style.resize = "both";
                    }

                    renderSpecificCM.style.cursor = "all-scroll";
                    if (uploadType !== "TXT") {
                        renderSpecificCM.style.border = "1px outset skyblue";
                    } else {
                        if (!contentObj.useAsBtn) {
                            renderSpecificCM.style.border = "1px outset skyblue";
                        }
                    }

                    //set for floatinng div over image
                    // specificFloatingDiv.style.width = contentMediaInfo.width;

                    // desable click action
                    setClickActionEnable(!clickActionEnable)
                }
            } else {
                renderAllCM[i].style.display = "none";
            }
        }

        // content media object info 
        setContentMediaObjectEditMode(contentMediaInfo);

        let currentTime = contentMediaInfo.startTime;
        if (uploadType === "hotspot") {
            currentTime = contentMediaInfo.startTime - 1;
        }
        await player.currentTime((currentTime - 1));
        await player.play();
        await player.pause();
        player.controls(true);
    }

    const cancel = async () => {
        // fixed place don't change row position
        setIsEditMode(false);
        sessionStorage.removeItem('pauseVideoSecongsSession');
        sessionStorage.removeItem('uploadType');
        sessionStorage.removeItem('contentId');

        if (fixedTemplateContent) {
            setFixedTemplateVisible(true);
        }
        setBranchingMappingShowHide(true);

        await player.currentTime(0);

        //
        // setFixedTemplateVisible(true);

        // if (contentMediaObjectEditMode.id === undefined) {
        //     setContentMediaSecHide(false);
        //     await player.play();
        //     await player.controls(true);
        //     return;
        // }

        let contentMediaId = contentMediaObjectEditMode.id;

        const contentMediaInfo = contentMediaObjectEditMode;
        const draggableProps = JSON.parse(contentMediaInfo.draggableProps);

        const renderSpecificCM = document.getElementById(`div_${contentMediaObjectEditMode.mediaType}_${contentMediaId}`);
        const renderAllCM = document.getElementsByClassName("draggable_child_div");
        // const floatingDivImg = document.getElementById(`floating_${contentMediaId}`);

        setContentMediaSecHide(false);

        try {
            for (let i = 0; i < renderAllCM.length; i++) {
                if (String(renderAllCM[i].id) === String(renderSpecificCM.id)) {
                    if (draggableProps.cancel === "") {
                        // set and change content media into JSON
                        draggableProps.cancel = "div";

                        // temp changes into content media
                        renderSpecificCM.style.width = contentMediaInfo.imageAreaWidth;
                        renderSpecificCM.style.transform = `translate(${draggableProps.defaultPosition.x}px, ${draggableProps.defaultPosition.y}px)`;
                        renderSpecificCM.style.cursor = "default";
                        renderSpecificCM.style.resize = "none";
                        renderSpecificCM.style.overflowX = "hidden";
                        renderSpecificCM.style.overflowY = "hidden";
                        renderSpecificCM.style.border = "none";

                        // enable click action
                        setClickActionEnable(!clickActionEnable)

                        // set properties
                        contentMediaInfo.draggableProps = JSON.stringify(draggableProps);
                    }
                }
                renderAllCM[i].style.display = "none";
            }
        } catch (error) { }

        // await player.play();
        await player.currentTime(0);
        await player.pause();
        player.controls(true);
    }

    // function removeSeekbarIcons() {
    //     let seekbarIconImage = document.getElementsByClassName("my-image");
    //     let seekbarIconHotspot = document.getElementsByClassName("my-hotspot");
    //     let seekbarIconText = document.getElementsByClassName("my-text");

    //     const length = [...seekbarIconImage, ...seekbarIconHotspot, ...seekbarIconText].length;
    //     if (length > 0) {
    //         for (let i = 0; i < length; i++) {
    //             if (seekbarIconImage.length > 0) {
    //                 seekbarIconImage[0].remove();
    //             }
    //             if (seekbarIconHotspot.length > 0) {
    //                 seekbarIconHotspot[0].remove();
    //             }
    //             if (seekbarIconText.length > 0) {
    //                 seekbarIconText[0].remove();
    //             }
    //         }

    //     }
    // }

    const returnToMainVideo = () => {
        if (templateVisible) {
            setTemplateVisible(false);
            setReturnBtnVisible(false);
            showTemplateHeader(); // set Header
            setBranchingMappingShowHide(true);
            setFixedTemplateVisible(true);
            player.play();
        } else {
            // alert("video return");
            // setReturnBtnVisible(false);
            // video_id = mainVideoId;
            // getVideoById(player);
            // setTimeout(() => {
            //     getBranchingData();
            //     removeSeekbarIcons();
            //     player.play();
            //     player.currentTime(0);
            // }, 500)
            window.location.reload(true);
        }
    }

    const closeFixedTemplate = () => {
        setFixedTemplateVisible(false);
        setReturnBtnVisible(false);
        showTemplateHeader(); // set Header
        player.play();
    }


    const makeJs = async (templateDataById) => {
        // Make Javascript for template
        if (templateDataById) {
            const scriptTag = document.createElement('script');
            scriptTag.id = "template_js";
            scriptTag.type = "text/javascript";
            scriptTag.text = templateDataById.templateJs;
            scriptTag.crossOrigin = "anonymous";
            // scriptTag.async = true;
            scriptTag.defer = true;
            return scriptTag;
        }
        return false;
    }

    const setMapping = async (object) => {
        if (isToggalMode || isEditMode) {
            return false;
        }

        //
        setReturnBtnVisible(false);
        setBranchingMappingShowHide(false);
        setFixedTemplateVisible(false);

        if (object.mappingType === 0) { // video mapping
            sessionStorage.setItem("switchVideoId", object.mappingId);
            setReturnBtnVisible(false);
            video_id = object.mappingId;
            setTimeout(() => {
                setReturnBtnVisible(true);
            }, (Number(object.replayVideoTime) * 1000));
            if (!mainVideoId) { setMainVideoId(object.videoId); }
            removeSeekbarIcons();
            getVideoById(player);
            getBranchingData();
            // debugger
            console.log(playerVideoInfo);
            // get template data
            let ww = await getTemplateById(object.mappingId);
            console.log(ww);
            setTimeout(() => {
                player.currentTime(0);
                player.play();
                setReturnBtnVisible(true);
                setBranchingMappingShowHide(true);
                // setFixedTemplateVisible(true);
            }, 500);

        } else if (object.mappingType === 1) { // template mapping
            debugger;
            player.pause();
            setFixedTemplateVisible(false);// desable fixed template
            setTemplateVisible(true); //  enable branching template

            let templateDataById = await getTemplateById(object.mappingId);
            setTemplateContent(templateDataById.templateContent);
            const scriptTag = await makeJs(templateDataById);
            if (scriptTag) {
                setTimeout(() => {
                    document.head.appendChild(scriptTag);
                }, 1000);
            }


            setTimeout(() => {
                setReturnBtnVisible(true);
            }, (Number(object.replayVideoTime) * 1000));

        } else {
            alert("invalid mapping type!!!");
        }
    }

    const showTemplateHeader = async () => {
        // const data = await getBranchingData();
        const templateList = await getVideoTemplateList(video_id);
        let templateObj = null;

        if (templateList) {
            if (templateList.length > 0) {
                // // find template based on priority
                // let templateObj = null;
                // data.map((object, index) => {
                //     let flag = false;
                //     if (object.mappingType === 1 && !flag) {
                //         flag = true;
                //         templateObj = object;
                //         // return false;
                //     }
                // });

                templateList.map((object, index) => {
                    let flag = false;
                    if (Number(object.templateType) === 1 && !flag) {
                        flag = true;
                        templateObj = object;
                    }
                });

                // if (fixedTemplateContent) {
                // if (fixedTemplateContent) {
                debugger
                console.log(playerVideoInfo);
                console.log(video_id);
                if (playerVideoInfo == "") {
                    setFixedTemplateVisible(true);
                } else if (String(playerVideoInfo.mainVideo.id) === String(video_id)) {
                    setFixedTemplateVisible(true);
                }
                // // }
                // let templateDataById = await getTemplateById(templateObj.mappingId);

                const scriptTag = await makeJs(templateObj);
                if (scriptTag) {
                    setTimeout(() => {
                        document.head.appendChild(scriptTag);
                    }, 50);
                }
                if (templateObj) {
                    setFixedTemplateContent(templateObj.fixedContent);
                }
                // returnBtnVisible(true);
            }
        }
    }

    const getVideoById = async (player) => {
        const mainvideoId = video_id;
        let objectFindById;

        if (mainvideoId) {
            let response = null;
            try {
                setLoader(true)
                response = await HttpRequest.GET("/private/main-video/" + mainvideoId);

                if (response.data !== "") {
                    setTimeout(() => {
                        setLoader(false)
                    }, 700);
                    objectFindById = response.data.object;
                    setVideoRenderType(Number(objectFindById.mainVideo.videoRenderType) === 1 ? true : false);
                    console.log(response);
                } else {
                    navigate("/");
                }

            } catch (err) {
                isSessionExpire();
            }

            try {
                let response1 = await HttpRequest.GET("/private/" + video_id + "/branch-mapping");
                if (response.data.status === "SUCCESS") {
                    const branchingList = response1.data.object.branchMappingList;
                    const draggableProps = JSON.parse(branchingList[0].draggableProps);
                    if (draggableProps) {
                        setDefualtDragPositionBranching(draggableProps.defaultPosition);
                    }
                }
            } catch (error) { }



            let draggablePropsObj = objectFindById.mainVideo.draggableProps ? JSON.parse(objectFindById.mainVideo.draggableProps) : null

            if (draggablePropsObj) {
                setDraggableProps(draggablePropsObj);
                if (draggablePropsObj.defaultPosition.x || draggablePropsObj.defaultPosition.y) {
                    setDefualtDragPosition(draggablePropsObj.defaultPosition);
                } else {
                    setDefualtDragPosition({ x: 200, y: 200 });
                }
                if (!landingPageObj) {
                    if (objectFindById.mainVideo.ctaContent) {
                        let ctaDisplayContent = objectFindById.mainVideo.ctaContent || "<p><button style=\"backgroung: #ddd; color: #e03e2d; padding: 5px;\">Visit Advertiser</button></p>";
                        if (objectFindById.mainVideo.landingCtaType) {

                            ctaDisplayContent = '<div class="customizedCTA_' + objectFindById.mainVideo.landingCtaType + '">';
                            ctaDisplayContent += objectFindById.mainVideo.landingCtaIcon ? '<img src="' + objectFindById.mainVideo.landingCtaIcon + '" class="customizedCTAImg_' + objectFindById.mainVideo.landingCtaType + '"/>' : '';
                            ctaDisplayContent += objectFindById.mainVideo.ctaContent ? '<span class="customizedCTATxt_' + objectFindById.mainVideo.landingCtaType + '">' + objectFindById.mainVideo.ctaContent + '</span>' : '';
                            ctaDisplayContent += objectFindById.mainVideo.landingCtaBtnLabel ? '<button class="customizedCTABtn_' + objectFindById.mainVideo.landingCtaType + '">' + objectFindById.mainVideo.landingCtaBtnLabel + '</button></div>' : '';
                        }

                        let newLandingPageObj = {
                            mainVideoId: objectFindById.mainVideo.id,
                            landingPageURL: objectFindById.mainVideo.landingPage || '',
                            landingPageDaggableProp: objectFindById.mainVideo.draggableProps ? JSON.parse(objectFindById.mainVideo.draggableProps) : {
                                "height": 61,
                                "width": 176,
                                "cancel": "div",
                                "grid": [
                                    1,
                                    1
                                ],
                                "defaultPosition": {
                                    "x": 0,
                                    "y": 308
                                }
                            },
                            landingPageContent: ctaDisplayContent,
                            landingCtaBtnLabel: objectFindById.mainVideo.landingCtaBtnLabel,
                            landingCtaIcon: objectFindById.mainVideo.landingCtaIcon,
                            landingCtaType: objectFindById.mainVideo.landingCtaType,
                            ctaStatus: objectFindById.mainVideo.ctaStatus
                        };
                        setLandingPageObj(newLandingPageObj);
                        if (objectFindById.mainVideo.ctaStatus == 1) {
                            setLandingPageObjVisible(true);
                        }
                    }
                }
            } else {
                setDefualtDragPosition({ x: 200, y: 200 });
            }


            setPlayerVideoInfo(objectFindById); // set fetch id details into state object
            setPlayerVideo(player, objectFindById);

            player.pause(); // pause the video when source change
            var list = await getListConMedia();
            manageEvents(player, list);
        } else {
            console.log("There have no id/null/undefined");
            // navigate("/");
        }

        return objectFindById;
    }

    const setPlayerVideo = (player, objectFindById) => {
        player.src([{
            src: objectFindById.mainVideo.videoPath + `?${Math.random()}`,
            type: objectFindById.mainVideo.videoContentType
        }]);
        //set player size
        if (objectFindById.mainVideo.playerWidth > 0 && objectFindById.mainVideo.playerHeight > 0) {
            player.width(objectFindById.mainVideo.playerWidth);
            player.height(objectFindById.mainVideo.playerHeight);
        }

        //  load responsive css acoording to player size
        let responsePlayerCss = document.createElement('link');
        responsePlayerCss.href = `https://test.truereach.co.in/stg-video/responsive-css/responsive_${objectFindById.mainVideo.playerWidth}_${objectFindById.mainVideo.playerHeight}.css`;
        responsePlayerCss.rel = 'stylesheet';
        document.head.appendChild(responsePlayerCss);

    }

    const getListConMedia = async () => {
        console.log("Fetch Content Media");

        let response = null;
        try {
            response = await HttpRequest.GET_BY_ID("/private/content-media/list", video_id);
        } catch (err) {
            isSessionExpire();
        }

        const data = response.data.object;
        console.log(data);
        let sortedData;

        if (response.data.status === "SUCCESS") {
            sortedData = data.sort((objectA, objectB) => parseFloat(objectA.startTime) - parseFloat(objectB.startTime));
            setContentMedia(sortedData);
            let quizIdIndexObj = {};
            sortedData.map((contentMedia, contentMediaIndex) => {
                if (contentMedia.mediaType === 'Form') {
                    return quizIdIndexObj[contentMedia.id] = 0;
                }
            });
            console.log('quizIdIndexObj', quizIdIndexObj);
            setQuizIndexObj(quizIdIndexObj);

            return sortedData;
        } else {
            console.log("Data not found con media");
        }
    }

    const getBranchingData = async () => {
        let sortedData;
        try {
            let response = null;
            try {
                response = await HttpRequest.GET("/private/" + video_id + "/branch-mapping");
            } catch (err) {
                isSessionExpire();
            }

            if (response.data.status === "SUCCESS") {
                // console.log(response);
                const branchingMappingData = response.data.object.branchMappingList;
                sortedData = branchingMappingData.sort((objectA, objectB) => parseFloat(objectA.mappingOrder) - parseFloat(objectB.mappingOrder));

                setBranchingMapping(sortedData);
                setBranchingDraggableProps(JSON.parse(branchingMappingData[0].draggableProps));
                return sortedData;
            } else {
                setBranchingMapping("");
            }
        } catch (err) {
            setBranchingMapping("");
        }

    }

    const manageEvents = async (player, list) => {
        let lastValue = 0;
        let flag1 = 0;
        var listConMedia = list; //await getListConMedia();
        console.log(video_id);
        player.ready(function () {
            this.on('timeupdate', function () {
                const rountCurrentTime = Math.round(this.currentTime());
                this.volume(0); // set volume to 0

                if (flag1 < 1) {
                    flag1++;
                    setTotalDuration(this.duration()); // set globlly tottal duration
                    getBranchingData();// show branching
                    this.player_.disablePictureInPicture(true)// disable picture in picture
                    manageSeekbarIcon(this.duration(), list, video_id);
                    showTemplateHeader();

                    // use this state when player is running after play button clicked
                    setIsPlayerRunningMode(true);
                    // if (landingPageObj) {
                    // setLandingPageObjVisible(true);
                    // }
                }

                if (lastValue < rountCurrentTime) {

                    lastValue = rountCurrentTime; // set last value

                    try {
                        const renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
                        for (let i = 0; i <= listConMedia.length; i++) {

                            if (Number(listConMedia[i].videoId) === Number(video_id)) {

                                // manage start time START
                                if (rountCurrentTime === Number(listConMedia[i].startTime) && Number(listConMedia[i].startTime) > 0 && Number(listConMedia[i].status) === 1) {
                                    let element = document.getElementById('template_div_id');
                                    if (element) {
                                        element.classList.add('hideAutoFixedTemp');
                                    }
                                    for (let j = 0; j < renderHtmlConMedia.length; j++) {

                                        const switchVideoId = sessionStorage.getItem("switchVideoId");

                                        // check condtion when video switch
                                        if (switchVideoId) {
                                            if (switchVideoId == listConMedia[i].videoId && listConMedia[i].status == 1) {
                                                renderHtmlConMedia[i].style.display = "block";
                                            } else {
                                                break;
                                            }
                                        }

                                        if (Number(listConMedia[i].isClickRequired) === 1) {
                                            setLandingPageObjVisible(false);
                                            setBranchingMappingShowHide(false);
                                            player.currentTime(Number(listConMedia[i].startTime));
                                            player.pause();
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                                            // renderHtmlConMedia[i].style.display = "none";
                                        }

                                        if (Number(listConMedia[i].isPauseEnable) === 1 && !isEditMode && Number(listConMedia[i].isClickRequired) !== 1) {
                                            pauseVideoSeconds(player, listConMedia[i], renderHtmlConMedia[i]);
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                                        }

                                        if (listConMedia[i].mediaType === 'Form') {
                                            setBranchingMappingShowHide(false);

                                            // setFixedTemplateVisible(false);

                                            let element = document.getElementById('template_div_id');
                                            if (element) {
                                                element.classList.add('hideAutoFixedTemp');
                                            }

                                            setLandingPageObjVisible(false);
                                            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                                            renderHtmlConMedia[i].style.display = "flex";
                                        } else {
                                            renderHtmlConMedia[i].style.display = "block";
                                        }
                                        // setQuizRender({'listConMedia': listConMedia, index: 1})
                                    }
                                    // console.log("start time match");
                                }
                                // manage start time END

                                // manage end time Start
                                if (rountCurrentTime === Number(listConMedia[i].endTime) && Number(listConMedia[i].endTime) > 0 && Number(listConMedia[i].status) === 1) {
                                    let element = document.getElementById('template_div_id');
                                    if (element) {
                                        element.classList.remove('hideAutoFixedTemp');
                                    }
                                    for (let j = 0; j < renderHtmlConMedia.length; j++) {
                                        let element = listConMedia[i];
                                        const uploadType = sessionStorage.getItem("uploadType");
                                        const contentId = sessionStorage.getItem("contentId");
                                        if ((`${uploadType}_${contentId}`) !== (`${element.mediaType}_${element.id}`)) {
                                            renderHtmlConMedia[i].style.display = "none";
                                        }
                                    }
                                    // console.log("end time match");
                                }
                                // manage end time END
                            }
                        }
                    } catch (error) {

                    }
                } else {
                    lastValue = rountCurrentTime; // set last value
                }

            })// update time end

            this.on('play', function () {
                this.player_.disablePictureInPicture(true)
                console.log("click play");
            })

            this.on('pause', function () {
                // this.play();
                console.log("click pause");
            })

            this.on('ended', function () {
                // this.play();
                console.log("ended");
            })

            this.bigPlayButton.on('click', function () {
                console.log("click Big Play Button");
            });

            this.on("seeked", function (event) {
                console.log("seeking");
            })
        });



        // =================================
        // Some VideoJs Events
        // =================================
        // 1. player.width(200) // set player width
        // 2. player.height(200) // set player height
        // 3. player.currentTime(8); // set start time
        // 4. this.duration(); // get total duration
        // 5. this.volume(0); // set volume
        // 6. player.reset(); // reset player no cantent to show in player

    }

    const getFormData = async (data) => {
        setLoader(true);
        let imageData = {};
        if (data.type === 'URL') {
            imageData = {
                videoId: video_id,
                imageName: Helper.getFileNameFromURL(data.formData),
                imageType: 'video/png',
                imagePath: data.formData,
                draggableProps: JSON.stringify({
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: { x: 30, y: 10 }
                })
            }
        } else {
            imageData = {
                videoId: video_id,
                imageName: data.name,
                imageSize: data.size,
                imageType: data.type,
                imagePath: data.base64EncodedImg,
                draggableProps: JSON.stringify({
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: { x: 30, y: 10 }
                })
            }
        }

        console.log(imageData);

        // send request to server for save image and it's default properties and redirect to edit image section.
        let response = null;
        try {
            response = await HttpRequest.POST("/private/add/image", imageData);
            console.log(response);
        } catch (err) {
            isSessionExpire();
        }

        const savedImage = response.data.object

        if (response.data.status === "SUCCESS") {
            setLoader(false);
            // setUploadImgModal(!uploadImgModal);
            setShow(!show);
            edit(savedImage, "image", 'initial');
        }
        // setContentMedia([savedImage]);
        setLoader(false);
        // setImageUpload(true);
    }

    const getImgFormData = async (data) => {
        setLoader(true);
        const renderSpecificCM = document.getElementById(`div_image_${data.id}`);
        let draggableProps;

        if (data.draggableProps !== "") {
            draggableProps = JSON.parse(data.draggableProps);
        }

        // set data 
        const formDataRequest = {
            // id: data.id,
            videoId: video_id,
            label: data.label,
            isLabelVisible: data.labelVisible,
            imageName: data.name,
            imageSize: data.size,
            imageType: data.type,
            imagePath: data.file,
            startTime: data.startTime,
            endTime: data.endTime,
            imageAreaWidth: renderSpecificCM ? `${renderSpecificCM.clientWidth - 2}px` : "100px",
            actionType: data.actionType,
            actionContent: data.actionType === 1 ? "" : data.actionLink,
            isPauseEnable: data.isPauseEnable,
            pauseLength: data.pauseLength,
            isLandingPage: data.isLandingPage,
            isBranching: data.isBranching,
            isClickRequired: data.isClickRequired,
            status: data.status,
            draggableProps: JSON.stringify({
                cancel: "div",
                grid: [1, 1],
                defaultPosition: deltaPosition
            })
        }

        console.log(formDataRequest);

        // call api and update data 
        let response = null;
        try {
            response = await HttpRequest.PUT("/private/image", data.id, formDataRequest);
        } catch (err) {
            isSessionExpire();
        }

        if (response.data.status === "SUCCESS") {
            alert("image added succesfully");
            window.location.reload(true);
        } else if (response.data.status === "REMOVE_SWITCHING") {
            alert("Image used in switching with other interaction please remove first.");
        } else {
            cancel(); // set defualt everthing after submit successfully
            setContentMediaSecHide(!contentMediaSecHide); // close edit form and show listing after submitted
        }

        setLoader(false)

    }

    // get hotspot data
    const getHotspotData = async (data) => {
        setLoader(true);
        let draggableProps;

        if (data.draggableProps !== "") {
            draggableProps = JSON.parse(data.draggableProps);
        }

        const formDataRequest = {
            videoId: video_id,
            label: data.label,
            // labelVisible: data.labelVisible,
            hotspotIconPath: data.file,
            startTime: data.startTime,
            endTime: data.endTime,
            actionLink: data.actionLink,
            actionType: data.actionType,
            isPauseEnable: data.isPauseHotspot,
            pauseLength: data.hotspotPauseLength,
            actionType: data.actionType,
            actionContent: data.actionLink,
            isLandingPage: data.isLandingPage,
            isClickRequired: data.isClickRequired,
            status: data.status,
            draggableProps: data.id ?
                JSON.stringify({
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: deltaPosition
                })
                : JSON.stringify({
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: deltaPosition
                })
        }

        let response = null;
        if (data.id) {
            try {
                response = await HttpRequest.PUT("/private/hotspot-setting/update", data.id, formDataRequest);
            } catch (err) {
                isSessionExpire();
            }
            alert("Updated Successfully");
        } else {
            try {
                response = await HttpRequest.POST("/private/hotspot-setting/add", formDataRequest);
            } catch (err) {
                isSessionExpire();
            }
            console.log(response);
            alert("Saved Successfully");
        }
        setLoader(false);
        window.location.reload(true);
    }

    const getTextFormData = async (data) => {
        setLoader(true);
        let draggableProps;
        if (data.draggableProps !== "") {
            draggableProps = JSON.parse(data.draggableProps);
        }
        const saveText = {
            videoId: video_id,
            label: data.label,
            textContent: data.textContent,
            startTime: data.startTime,
            endTime: data.endTime,
            isPauseEnable: data.isPauseText,
            pauseLength: data.textPauseLength,
            isLandingPage: data.isLandingPage,
            isClickRequired: data.isClickRequired,
            useAsBtn: data.useAsBtn,
            actionType: data.actionType,
            actionContent: data.actionContent,
            status: data.status,
            draggableProps: data.id ?
                JSON.stringify({
                    height: data.height,
                    width: data.width,
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: deltaPosition
                })
                : JSON.stringify({
                    height: data.height,
                    width: data.width,
                    cancel: "div",
                    grid: [1, 1],
                    defaultPosition: deltaPosition
                })
        }

        let response = null;
        try {
            if (data.id) {
                response = await HttpRequest.PUT("/private/text-setting/update", data.id, saveText);
                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    alert("Text setting update successfully...");
                }
            } else {
                response = await HttpRequest.POST("/private/text-setting/add", saveText);
                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    alert("Text setting save successfully...");
                }
            }
        } catch (err) {
            isSessionExpire();
        }

        setLoader(false);
        window.location.reload(true);

    }

    const clickAction = async (object, i) => {
        debugger;
        let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
        if (clickActionEnable === true && !isToggalMode) {
            document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
            if (Number(object.actionType) === 2) {
                player.play();
                player.controls(true);
                setBranchingMappingShowHide(true);
                setFixedTemplateVisible(true);
                setLandingPageObjVisible(true);

                window.open(object.actionContent, "_blank");
                if (!isEditMode) {
                    // renderHtmlConMedia[i].setAttribute("style", "display:none;");
                    renderHtmlConMedia[i].style.display = "none";
                }

            } else if (Number(object.actionType) === 5) { // show message
                setTemplateVisible(false);
                // setFixedTemplateVisible(false);
                setBranchingMappingShowHide(false);
                setLandingPageObjVisible(false);
                player.pause();
                player.controls(false);
                setAction(object.actionContent);
                setShowMsgEnable(true);
                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div parentOverlay';
                if (!isEditMode) {
                    // renderHtmlConMedia[i].setAttribute("style", "display:none;");
                    renderHtmlConMedia[i].style.display = "none";
                }
                if (sessionStorage.getItem("timerId")) {
                    sessionStorage.getItem("timerId").split(",").map((timerId) => {
                        clearTimeout(timerId);
                    });
                    //    clearTimeout(sessionStorage.getItem("timerId")); 
                    sessionStorage.removeItem('timerId');
                }
            } else if (Number(object.actionType) === 3) {
                if (sessionStorage.getItem("timerId")) {
                    sessionStorage.getItem("timerId").split(",").map((timerId) => {
                        clearTimeout(timerId);
                    });
                    sessionStorage.removeItem('timerId');
                    setFixedTemplateVisible(true);
                }

                setReturnBtnVisible(false);
                setBranchingMappingShowHide(false);
                // setFixedTemplateVisible(false);
                setBranchingMapping("");

                let actionContentInfo = JSON.parse(object.actionContent);
                sessionStorage.setItem("switchVideoId", actionContentInfo.value);
                video_id = actionContentInfo.value;
                setTimeout(() => { setReturnBtnVisible(true); }, 1000);
                if (!mainVideoId) setMainVideoId(object.videoId);
                getVideoById(player);
                removeSeekbarIcons();

                setTimeout(() => {
                    player.play();
                    getBranchingData();
                    setBranchingMappingShowHide(true);
                    setFixedTemplateVisible(true);
                }, 500);

            } else if (Number(object.actionType) === 6) {
                if (sessionStorage.getItem("timerId")) {
                    sessionStorage.getItem("timerId").split(",").map((timerId) => {
                        clearTimeout(timerId);
                    });
                    sessionStorage.removeItem('timerId');
                    setFixedTemplateVisible(true);
                }

                let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                previousElement.style.display = 'none';

                let actionContentInfo = JSON.parse(object.actionContent);
                const data = await getImageById(actionContentInfo.value);
                let element = document.getElementById(`div_${data.mediaType}_${data.id}`);
                element.style.display = 'block';

                // desable when image 
                player.pause();
                player.controls(false);
                setBranchingMappingShowHide(false);
                // setFixedTemplateVisible(false);

            } else if (Number(object.actionType) === 7 || Number(object.actionType) === 8) {
                if (sessionStorage.getItem("timerId")) {
                    sessionStorage.getItem("timerId").split(",").map((timerId) => {
                        clearTimeout(timerId);
                    });
                    sessionStorage.removeItem('timerId');
                    setFixedTemplateVisible(true);
                }

                let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                previousElement.style.display = 'none';

                let actionContentInfo = JSON.parse(object.actionContent);
                const data = await getFormQuizById(actionContentInfo.value);
                let element = document.getElementById(`div_${data.mediaType}_${data.id}`);
                element.style.display = 'flex';
                // if(object.mediaType == "Form") {
                // element.style.display = 'flex';
                // }

                // desable when image 
                player.pause();
                player.controls(false);
                setBranchingMappingShowHide(false);
                // setFixedTemplateVisible(false);

                console.log("switch image")

            } else if (Number(object.actionType) === 1 || Number(object.actionType) === -1 || !object.actionType) {
                if (Number(object.isPauseEnable) !== 1) {
                    player.play();
                    player.controls(true);
                    setBranchingMappingShowHide(true);
                    setFixedTemplateVisible(true);
                    setLandingPageObjVisible(true);

                    if (object.isClickRequired == 1) {
                        let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                        previousElement.style.display = 'none';
                    }

                    if (Number(object.status) !== 1) {
                        let previousElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
                        previousElement.style.display = 'none';
                    }
                } else if (Number(object.isPauseEnable) === 1 && !object.actionType) {
                    player.play();
                    player.controls(true);
                    setBranchingMappingShowHide(true);
                    setFixedTemplateVisible(true);
                    renderHtmlConMedia[i].style.display = "none";
                }
            }
        }

        if (!isEditMode) {
            if (Number(object.isClickRequired) === 1 && object.mediaType === "hotspot") {
                // renderHtmlConMedia[i].setAttribute("style", "display:none;");
                renderHtmlConMedia[i].style.display = "none";
            }
        }

    }

    function pauseVideoSeconds(player, mediaObject, htmlRenderMedia) {
        console.log('-----', player, mediaObject, htmlRenderMedia)
        setPauseSecMode(true);
        setLandingPageObjVisible(false);
        player.pause();

        var pauseVideoSecongsSession = sessionStorage.getItem("pauseVideoSecongsSession");
        if (pauseVideoSecongsSession !== "true") {
            player.controls(false);
            player.currentTime(Number(mediaObject.startTime));
            setBranchingMappingShowHide(false);
            // setFixedTemplateVisible(false);

            idTimeOut = setTimeout(() => {
                console.log('setTimeout called');
                player.play();
                player.controls(true);
                setLandingPageObjVisible(true);
                setBranchingMappingShowHide(true);
                // if (fixedTemplateContent) {
                setFixedTemplateVisible(true);
                // }
                htmlRenderMedia.style.display = "none";
                setPauseSecMode(false);
                sessionStorage.removeItem("timerId");
                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
            }, Number(mediaObject.pauseLength));
            if (sessionStorage.getItem("timerId")) {
                sessionStorage.setItem("timerId", sessionStorage.getItem("timerId") + ',' + idTimeOut);
            } else {
                sessionStorage.setItem("timerId", idTimeOut);
            }
        } else { }
    }

    const closePopUp = () => {
        setShowMsgEnable(false);
        player.play();
        player.controls(true);
        setLandingPageObjVisible(true);
        if (templateContent) {
            setTemplateVisible(true);
        }
        // if (fixedTemplateContent) {
        //     setFixedTemplateVisible(true);
        // }
        let element = document.getElementById('template_div_id');
        if (element) {
            element.classList.remove('hideAutoFixedTemp');

        }
        setPauseSecMode(false)
        setBranchingMappingShowHide(true);
        document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';

    }

    const redirectToQuizForm = (formId) => {
        console.log(formId);
        if (formId) {
            navigate("/quiz-form/" + video_id,
                { state: { id: video_id, formId: formId } }
            );
        } else {
            navigate("/quiz-form/" + video_id,
                { state: { id: video_id } }
            );
        }

    }

    // function for quiz
    function manageQuizFieldData(event, object, index, fieldIndex) {
        console.log(event);
        console.log(object.fields.length - 1);
        // console.log(quizIndexObj[object.id]);

        // event.nativeEvent.stopImmediatePropogation();
        if (object.fields.length - 1 > fieldIndex) {
            quizIndexObj[object.id] = quizIndexObj[object.id] + 1;
            setQuizIndexObj({ ...quizIndexObj });
        } else {
            clickAction(object, index);
        }
        return false;
    }

    const triggerLandingURL = function (event) {
        try {
            event.stopPropagation();
            let url = landingPageObj.landingPageURL;
            if (url) {
                window.open(url, "_blank");
            }
        } catch (e) { console.log(e) }
        return true;
    }

    function changePosition(position) {
        let element = null;
        let increment = 1;

        element = document.getElementById(`div_${uploadType}_${contentMediaId}`);
        console.log("Elements => x:" + element.clientWidth, " y:" + element.clientHeight);

        if (position === "top" && deltaPosition.y > 0) {
            setDeltaPosition({ ...deltaPosition, ["y"]: deltaPosition.y - increment });
            element.style.transform = `translate(${deltaPosition.x}px, ${deltaPosition.y - increment}px)`;
        } else if (position === "left" && deltaPosition.x < 499) {
            setDeltaPosition({ ...deltaPosition, ["x"]: deltaPosition.x + increment });
            element.style.transform = `translate(${deltaPosition.x + increment}px, ${deltaPosition.y}px)`;
        } else if (position === "bottom" && deltaPosition.y < 219) {
            setDeltaPosition({ ...deltaPosition, ["y"]: deltaPosition.y + increment });
            element.style.transform = `translate(${deltaPosition.x}px, ${deltaPosition.y + increment}px)`;
        } else if (position === "right" && deltaPosition.x > 0) {
            setDeltaPosition({ ...deltaPosition, ["x"]: deltaPosition.x - increment });
            element.style.transform = `translate(${deltaPosition.x - increment}px, ${deltaPosition.y}px)`;
        }
    }

    const resizableEvent = (e, object) => {
        // const divElement = document.getElementById(`div_${object.mediaType}_${object.id}`);
        const divElement = document.getElementsByClassName(`draggable_parent_div`)[0];
        // let divElement = document.querySelector('.draggable_parent_div').getBoundingClientRect();
        console.log(divElement.top);
    }

    // function getCtaSettingsData(data) {
    //     const defaultPosition = data.draggableProps.defaultPosition;
    //     setDefualtDragPosition(defaultPosition);
    // }

    // call publisher API 
    async function publisherInfo() {
        let response = await axios({
            method: 'get',
            url: 'http://localhost:8001/fake/publisher/data'
        });
        console.log(response.data.object);
        setPublisherData(response.data.object);
    }

    function redireactTo(url) {
        window.location.href = url;
    }

    useEffect(() => {
        // RUN BEFORE LOAD JSX
        var player = videojs('my-video');
        setPlayer(player);

        if (paramArray.length > 0) {
            debugger
            let data = paramArray[1].split("=");
            uploadImgToggle(data[0], player);
            setUploadType(data[0]);
        }
        getVideoById(player);
        // publisherInfo();
        /// remove session
        sessionStorage.removeItem('pauseVideoSecongsSession');
        sessionStorage.removeItem('uploadType');
        sessionStorage.removeItem('contentId');

        // let linkElem = document.createElement('link');
        // linkElem.href = 'https://stg-video.momagic.com/responsive-css/responsive_300_250.css';
        // linkElem.rel = 'stylesheet';
        // document.head.appendChild(linkElem);

    }, []);

    useEffect(() => {
        try {
            if (String(playerVideoInfo.mainVideo.seekbarControls) === '0') {
                document.getElementsByClassName('vjs-progress-control')[0].style.pointerEvents = 'none';
            }
        } catch (e) { }
        var player = videojs('my-video');
        // manageSeekbarIcon(player);
    }, [playerVideoInfo]);

    useEffect(() => {
        if (isEditMode) {
            // document.getElementsByClassName('vjs-control-bar')[0].setAttribute('style', 'display:flex !important');
            document.getElementsByClassName('vjs-control-bar')[0].style.display = 'flex !important';
        }
    }, [isEditMode]);

    // useEffect( () => {
    //     Array.from(document.querySelectorAll('#cta_settings p')).map(
    //         (e, i) => {
    //             e.style.marginBottom = "0px";
    //             // e.style.fontSize = Math.abs(( 21 * calcVideoWidthPercent)/100) + 'px';
    //         }
    //     )
    // }, [uploadType]);
    // useEffect( () => {
    //     if(imageUpload) {
    //         const renderAllCM = document.getElementsByClassName("draggable_child_div");
    //         const renderSpecificCM = document.getElementById(`div_${contentMedia[0].mediaType}_${contentMedia[0].id}`);
    //         const draggableProps = contentMedia[0].draggableProps;
    //         const uploadType = 'image';
    //         for (let i = 0; i < renderAllCM.length; i++) {
    //             if (String(renderAllCM[i].id) === String(renderSpecificCM.id)) {
    //                 if (draggableProps.cancel === "div") {
    //                     // set and chnage content media JSON
    //                     draggableProps.cancel = "";
    //                     contentMedia[0].draggableProps = JSON.stringify(draggableProps);
    //                     // temp changes into content media
    //                     renderSpecificCM.style.overflow = "auto";
    //                     renderSpecificCM.style.display = "block";

    //                     if (uploadType !== "hotspot" && uploadType !== "TXT") {
    //                         // renderSpecificCM.style.resize = "none";
    //                         renderSpecificCM.style.resize = "horizontal";
    //                     }

    //                     if (uploadType === "TXT") {
    //                         renderSpecificCM.style.resize = "both";
    //                     }

    //                     renderSpecificCM.style.cursor = "all-scroll";
    //                     renderSpecificCM.style.border = "6px outset skyblue";

    //                     //set for floatinng div over image
    //                     // specificFloatingDiv.style.width = contentMediaInfo.width;

    //                     // desable click action
    //                     setClickActionEnable(!clickActionEnable)
    //                 }
    //             } else {
    //                 renderAllCM[i].style.display = "none";
    //             }
    //         }
    //     }
    // }, [imageUpload]);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <div className='playerTitleBtns'>
                <div className='playerVideoTitle'>
                    <h2>
                        Title : {playerVideoInfo ? playerVideoInfo.mainVideo.label : ""}
                    </h2>
                </div>
                <div className='playerOptBtns'>
                    {/* Buttons For Interaction configuration Branching */}
                    {
                        !isToggalMode && !isEditMode ?
                            <div className='playerBranchingBtns'>
                                <div className="mb-2">
                                    {/* <div className='cloneVideos'> */}
                                    {
                                        playerVideoInfo ?
                                            playerVideoInfo.mainVideo.parentVideoId > 0
                                                ? <Button className='btn btn-success' onClick={() => redireactTo(`/player/${playerVideoInfo ? playerVideoInfo.mainVideo.parentVideoId : ""}`)}>Parent Video</Button>
                                                :
                                                <Link className='text-light' to={"/video-interaction"} state={{ id: video_id }}><button className='btn btn-primary'>Tracking Events</button></Link>
                                            : null
                                    }
                                    {/* </div> */}
                                    <Link className='text-light' to={"/video-config"} state={{ id: video_id }}><button className='btn btn-primary'>Video Configuration </button></Link>
                                    {/* <Link className='text-light' to={"/video-branching"} state={{ id: video_id }}><button className='btn btn-primary'>Video Branching</button></Link> */}
                                    <Link className='text-light' to={"/video-tag-generation"} state={{ id: video_id }}><button className='btn btn-primary'>Video Tag Generation</button></Link>
                                </div>
                            </div>
                            : null
                    }
                </div>
            </div>
            <div className="panel_body_fl">
                {
                    !isToggalMode && !isEditMode ?
                        <div className="panel_side_icons">
                            <ul>
                                <li onClick={() => uploadImgToggle("image")}>
                                    <img src="/assets/icon_images_2.png" alt="images" />
                                    <span>Image</span>
                                </li>
                                <li onClick={() => redirectToQuizForm()}>
                                    <img src="/assets/icon_form_1.png" alt="form" />
                                    <span>Form / Quiz</span>
                                </li>
                                {/* <li onClick={() => redirectToQuizForm()}>
                            <img src="/assets/icon_quiz_2.png" alt="Quiz" />
                            <span>Quiz</span>
                        </li> */}
                                <li onClick={() => uploadImgToggle("TXT")}>
                                    <img src="/assets/icon_text_2.png" alt="Text" />
                                    <span>Text</span>
                                </li>
                                {/* <li>
                            <img src="/assets/icon_popup_4.png" alt="Modal" />
                            <span>Popup</span>
                        </li> */}
                                <li onClick={() => uploadImgToggle("hotspot")}>
                                    <img src="/assets/icon_hotspot_2.png" alt="Hotspot" />
                                    <span>Hotspot</span>
                                </li>
                                <li onClick={() => uploadImgToggle("cta_settings")}>
                                    <img src="/assets/cta_settings.png" alt="cta" />
                                    <span>CTA</span>
                                </li>
                                <li onClick={() => uploadImgToggle("template")}>
                                    <img src="/assets/template.png" alt="template" />
                                    <span>Template</span>
                                </li>
                                <li onClick={() => uploadImgToggle("branching")}>
                                    <img src="/assets/branching.png" alt="template" style={{ width: "40px" }} />
                                    <span>Branching</span>
                                </li>
                            </ul>
                        </div>
                        : null
                }

                <div className="panel_video">


                    <div className="draggable_parent_div">
                        <video id="my-video" className="video-js" preload="auto" width={640} height={360} poster="" autoPlay={videoRenderType} controls data-setup='{ }'>
                            <source id="source" src={"/assets/kurti"} type="video/mp4" />
                        </video>

                        {/* {
                            isEditMode && !isToggalMode ?
                                <div>
                                    <ButtonGroup className="mb-2 movingOptionBtn">
                                        <Button onClick={() => changePosition("right")}>Right</Button>
                                        <Button onClick={() => changePosition("left")}>Left</Button>
                                        <Button onClick={() => changePosition("top")}>Top</Button>
                                        <Button onClick={() => changePosition("bottom")}>Bottom</Button>
                                    </ButtonGroup>

                                </div>
                                : null
                        } */}

                        {
                            showMsgEnable === true ?
                                <div className='player_popup'>
                                    <div className='player_popup_child' dangerouslySetInnerHTML={{ __html: action }}></div>
                                    <button className='modal_cross' onClick={closePopUp}></button>
                                </div>
                                : null
                        }
                        {
                            // videoStarted && landingPageObj ?
                            !isEditMode && landingPageObjVisible && landingPageObj.ctaStatus == 1 && isPlayerRunningMode ?
                                <div className={landingPageObj.landingCtaType ? 'draggable_child_div_cta react-draggable' : 'draggable_child_div_cta react-draggable cta_button'} id={`cta_settings`}
                                    onClick={(event) => triggerLandingURL(event)}
                                    dangerouslySetInnerHTML={{ __html: landingPageObj.landingPageContent }}
                                    style={{
                                        // height: landingPageObj.landingPageDaggableProp.height,
                                        // width: landingPageObj.landingPageDaggableProp.width,
                                        transform: 'translate(' + landingPageObj.landingPageDaggableProp.defaultPosition.x + 'px, ' + landingPageObj.landingPageDaggableProp.defaultPosition.y + 'px)',
                                        display: 'block'
                                    }}>
                                </div>
                                // <button className='bg-primary edit_btn_danger btnBlue' onClick={(event) => triggerLandingURL(event)} style={{ left: "0", right: "auto" }}>
                                //     Trigger Landing URL
                                // </button>
                                : null
                        }
                        {
                            returnBtnVisible ?
                                <button className='edit_btn_danger' onClick={returnToMainVideo}>
                                    Return <img src="/assets/returnIcon.png" />
                                </button>
                                : null
                        }


                        {/* {
                            branchingMapping ?
                                <div className="branching_edit_btns" style={{ display: "flex" }}>
                                    <button className='bg-primary'>
                                        <span onClick={() => setBranchingMappingShowHide(!branchingMappingShowHide)} className="btn-danger">
                                            {branchingMappingShowHide === true ? "Hide Branching" : "Show Branching"}
                                        </span>
                                    </button>

                                </div>
                                : null
                        } */}

                        {
                            // Template Show when Branch click
                            templateVisible ?
                                <div className="template_div" style={{ height: "100%", background: "" }} dangerouslySetInnerHTML={{ __html: templateContent }} ></div>
                                : null
                        }

                        {
                            fixedTemplateVisible ?
                                <div>
                                    <button id="btn_return_fixed" onClick={closeFixedTemplate} className='edit_btn_danger' style={{ display: "none" }}>Return <img src="/assets/returnIcon.png" /></button>
                                    <div id="template_div_id" className="template_div" onClick={() => player.pause()} dangerouslySetInnerHTML={{ __html: fixedTemplateContent }} ></div>
                                </div>
                                : null
                        }


                        {
                            mainVideoId ? null :
                                isEditMode || showMsgEnable || branchingMappingShowHide !== true ? null :
                                    branchingMapping ?
                                        <div className='player_popup_branching' style={{
                                            transform: `translate(${branchingDraggableProps.defaultPosition.x}px,${branchingDraggableProps.defaultPosition.y}px)`
                                        }}>
                                            <div className={`branching_icons ${Number(branchingMapping[0].branchingTheme) === 1 ? "branchingVertical" : ""}`} >
                                                {
                                                    branchingMapping.map((object, index) => {
                                                        return (
                                                            <React.Fragment key={object.id}>
                                                                <div className={object.mappingType === 1 ? "first_video_btns branchingTemplate" : "first_video_btns"} onClick={() => setMapping(object)}>
                                                                    <img src={object.mappingIcon} />
                                                                    <span>{object.mappingLabel}</span>
                                                                </div>
                                                            </React.Fragment>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : console.log("Branching Mapping Not Found...")
                        }

                        {
                            isToggalMode ?
                                <>
                                    {
                                        uploadType === "cta_settings" ?
                                            <Draggable key={""} handle="section" onDrag={handleDrag}
                                                defaultPosition={defualtDragPosition}
                                                bounds="video" {...dragHandlers} >
                                                <div className="draggable_child_container">
                                                    <section>
                                                        <div id={`cta_settings`} className="cta_button">
                                                            {/* content inserted dynamically */}
                                                        </div>
                                                    </section>
                                                </div>
                                            </Draggable>
                                            : null
                                    }
                                    {
                                        uploadType === "branching" ?
                                            <Draggable key={""} handle="section" onDrag={handleDrag}
                                                defaultPosition={defualtDragPositionBranching}
                                                bounds="video" {...dragHandlers} >
                                                <div className="draggable_child_container">
                                                    <section>
                                                        {
                                                            branchingMapping ?
                                                                <div id='toggal_branching_icons' className="branching_icons">
                                                                    {/* content inserted dynamically */}
                                                                </div>
                                                                : null
                                                        }
                                                    </section>
                                                </div>
                                            </Draggable>
                                            : null
                                    }
                                </>
                                : null
                        }

                        <div>
                            {
                                contentMedia.map((object, index) => {
                                    const draggableProps = object.draggableProps === null ? "" : JSON.parse(object.draggableProps);
                                    return (
                                        <Draggable key={object.mediaType + object.id} handle="section" onDrag={handleDrag} grid={draggableProps.grid}
                                            cancel={draggableProps.cancel} defaultPosition={draggableProps.defaultPosition}
                                            bounds="video" {...dragHandlers}>
                                            <div onMouseLeave={(e) => resizableEvent(e, object)} id={`div_${object.mediaType}_${object.id}`} style={
                                                object.mediaType === "image"
                                                    ? { width: object.imageAreaWidth, zIndex: "10" }
                                                    : object.mediaType === "TXT" ?
                                                        !object.useAsBtn ?
                                                            { width: draggableProps.width === "auto" ? draggableProps.width : draggableProps.width + "px", height: draggableProps.height === "auto" ? draggableProps.height : draggableProps.height + "px", background: "#fff", overflow: "auto", zIndex: "11", maxWidth: playerVideoInfo.mainVideo.playerWidth, maxHeight: playerVideoInfo.mainVideo.playerHeight }
                                                            : null
                                                        : object.mediaType === "hotspot" ?
                                                            { overflow: "unset" }
                                                            : null
                                            } className={object.mediaType === "TXT" && object.useAsBtn ? "draggable_child_div text-button" : object.mediaType === "Form" ? "draggable_child_div formZindex" : object.mediaType === "hotspot" ? "draggable_child_div hotspotZindex" : "draggable_child_div"} >
                                                <section onClick={() => clickAction(object, index)} id={`section_${object.mediaType}_${object.id}`}>

                                                    {object.mediaType === "image" ?
                                                        <div className="image_div">
                                                            <div id={`floating_div_${object.mediaType}_${object.id}`} style={{ width: object.imageAreaWidth, background: "", position: "absolute" }}></div>
                                                            <img id={`image${(object.id)}`} className={`player_dyn_img ${object.isBranching === 1 ? "img_branching" : ""}`} src={object.imagePath} alt='logo-test' />
                                                        </div>
                                                        : null
                                                    }
                                                    {object.mediaType === "hotspot" ?
                                                        <span style={{ padding: "0px", justifyContent: "center", display: "flex", alignItems: "center", cursor: isEditMode ? "all-scroll" : "pointer" }}>
                                                            <div className="shopping_02_cart_icon">
                                                                <img id={`icon${object.id}`} src={object.hotspotIconPath} />
                                                            </div>
                                                        </span>
                                                        : null
                                                    }
                                                    {object.mediaType === "TXT" ?
                                                        <span style={{ justifyContent: "center", alignItems: "center" }}>
                                                            <div id={`text${object.id}`} className="text-container-r textPara" dangerouslySetInnerHTML={{ __html: publisherData ? publisherData : object.textContent }} style={{ padding: "0 9px" }}>
                                                                {/* content inserted dynamically */}
                                                            </div>
                                                        </span>
                                                        : null
                                                    }
                                                    {/* form rendering */}
                                                    {object.mediaType === "Form" && object.formType === 1 ?

                                                        <div className='container formQzBg formQzCom' onClick={(event) => { event.stopPropagation() }} >
                                                            <h2>{object.formTitle}</h2>
                                                            {
                                                                object.fields.map((field, index) => {
                                                                    // console.log(field);
                                                                    return (
                                                                        <div className='row formPlayerResp' key={index}>
                                                                            <span className='fieldsLabel'>{field.label}</span>

                                                                            {
                                                                                field.type === 1 ? <input type="text" id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                                            }
                                                                            {/* select box start */}
                                                                            {
                                                                                field.type === 2 ?
                                                                                    <select className='config_poster_inp'>
                                                                                        {
                                                                                            <option value=''>
                                                                                                -- Please select option --
                                                                                            </option>
                                                                                        }
                                                                                        {
                                                                                            JSON.parse(field.options).map((option, optionIndex) => {
                                                                                                return (
                                                                                                    <option key={optionIndex} value={option.value}>
                                                                                                        {option.value}
                                                                                                    </option>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                    : null
                                                                            }
                                                                            {/* radio start */}
                                                                            <div className='optionsRow'>
                                                                                {
                                                                                    field.type === 3 ?

                                                                                        JSON.parse(field.options).map((option, optionIndex) => {
                                                                                            return (

                                                                                                <div key={optionIndex} style={{ display: 'flex', margin: '0 10px 10px 0' }} className=''>
                                                                                                    <input type='radio' value={option.value} name="forms_setting" />
                                                                                                    <span> {option.value} </span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            {/* checkbox start */}
                                                                            <div className='optionsRow'>
                                                                                {
                                                                                    field.type === 4 ?
                                                                                        JSON.parse(field.options).map((option, optionIndex) => {
                                                                                            return (
                                                                                                <div key={optionIndex} style={{ display: 'flex', margin: '0 10px 10px 0' }}>
                                                                                                    <input type='checkbox' value={option.value} name="forms_setting" />
                                                                                                    <span>{option.value}</span>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                        : null
                                                                                }
                                                                            </div>
                                                                            {/* text area start */}
                                                                            {
                                                                                field.type === 5 ? <textarea id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here"></textarea> : null
                                                                            }
                                                                            {/* datepicker start */}
                                                                            {
                                                                                field.type === 6 ? <input type='date' id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                                            }

                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            <button className='btn_save' onClick={() => { setBranchingMappingShowHide(true); if (fixedTemplateContent) { let element = document.getElementById('template_div_id'); if (element) { element.classList.remove('hideAutoFixedTemp') }; }; clickAction(object, index) }}>{object.buttonLabel}</button>
                                                            <div className='quizCross' onClick={() => {
                                                                setShowMsgEnable(false);
                                                                setLandingPageObjVisible(true);
                                                                if (!isEditMode || !isToggalMode) {
                                                                    player.play();
                                                                    player.controls(true);
                                                                }
                                                                let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
                                                                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
                                                                renderHtmlConMedia[index].style.display = "none";
                                                                setBranchingMappingShowHide(true);
                                                                if (fixedTemplateContent) {
                                                                    // setFixedTemplateVisible(true);
                                                                    let element = document.getElementById('template_div_id');
                                                                    if (element) {
                                                                        element.classList.remove('hideAutoFixedTemp');

                                                                    }
                                                                }
                                                                // player.controls(true);
                                                            }}></div>
                                                        </div>
                                                        : null
                                                    }
                                                    {/* quiz rendering */}
                                                    {object.mediaType === "Form" && object.formType === 2 ?
                                                        <div className='container quizRenderBg formQzCom' id={`quiz_${(object.id)}`} onClick={(event) => { event.stopPropagation() }} >
                                                            <h2>{object.formTitle}</h2>
                                                            <h5>Question {quizIndexObj[object.id] + 1} out of {object.fields.length}</h5>
                                                            <span className='fieldsLabel'>{object.fields[quizIndexObj[object.id]].label}</span>
                                                            {/* text field */}
                                                            {
                                                                object.fields[quizIndexObj[object.id]].type === 1 ?

                                                                    <input type="text" id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" />

                                                                    : null
                                                            }
                                                            {/* select box */}
                                                            {
                                                                object.fields[quizIndexObj[object.id]].type === 2 ?
                                                                    <select className='config_poster_inp'>
                                                                        {
                                                                            <option value=''>
                                                                                -- Please select option --
                                                                            </option>
                                                                        }
                                                                        {
                                                                            JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {
                                                                                console.log(quizOption)

                                                                                return (

                                                                                    <option key={quizOptionIndex} value={quizOption.value}>
                                                                                        {quizOption.value}
                                                                                    </option>

                                                                                )
                                                                            })
                                                                        }

                                                                    </select>
                                                                    : null

                                                            }
                                                            {/* radio start */}
                                                            <div className='optionsRow'>
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 3 ?
                                                                        JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {
                                                                            return (
                                                                                <div key={quizOptionIndex + '-' + quizIndexObj[object.id]} style={{ display: 'flex', margin: '0 10px 10px 0' }}>
                                                                                    <input type='radio' value={quizOption.value} name={quizIndexObj[object.id]} />
                                                                                    <span> {quizOption.value} </span>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        : null
                                                                }
                                                            </div>
                                                            {/* checkbox start */}
                                                            <div className='optionsRow'>
                                                                {
                                                                    object.fields[quizIndexObj[object.id]].type === 4 ?

                                                                        JSON.parse(object.fields[quizIndexObj[object.id]].options).map((quizOption, quizOptionIndex) => {

                                                                            return (
                                                                                <div key={quizOptionIndex} style={{ display: 'flex', margin: '0 10px 10px 0' }}>
                                                                                    <input type='checkbox' value={quizOption.value} name="forms_setting" />
                                                                                    <span> {quizOption.value} </span>
                                                                                </div>

                                                                            )
                                                                        })


                                                                        : null
                                                                }
                                                            </div>
                                                            {/* text area start */}
                                                            {
                                                                object.fields[quizIndexObj[object.id]].type === 5 ? <textarea id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here"></textarea>
                                                                    : null
                                                            }
                                                            {/* datepicker start */}
                                                            {
                                                                object.fields[quizIndexObj[object.id]].type === 6 ? <input type='date' id="poster_img" className="config_poster_inp fieldOptions" placeholder="Enter here" /> : null
                                                            }
                                                            {
                                                                (object.fields.length - 1 > quizIndexObj[object.id]) ?
                                                                    <button className='quizBtnnw btn_save' onClick={(event) => { manageQuizFieldData(event, object, index, quizIndexObj[object.id]) }}>Continue</button>
                                                                    : <button className='quizBtnnw btn_save' onClick={(event) => { setBranchingMappingShowHide(true); if (fixedTemplateContent) { let element = document.getElementById('template_div_id'); if (element) { element.classList.remove('hideAutoFixedTemp'); } }; manageQuizFieldData(event, object, index, quizIndexObj[object.id]) }}>{object.buttonLabel}</button>

                                                            }
                                                            <div className='quizCross' onClick={() => {
                                                                setShowMsgEnable(false);
                                                                setLandingPageObjVisible(true);
                                                                if (!isEditMode || !isToggalMode) {
                                                                    player.play();
                                                                    player.controls(true);
                                                                }
                                                                let renderHtmlConMedia = document.getElementsByClassName("draggable_child_div");
                                                                document.getElementsByClassName('draggable_parent_div')[0].classList = 'draggable_parent_div';
                                                                renderHtmlConMedia[index].style.display = "none";
                                                                setBranchingMappingShowHide(true);
                                                                if (fixedTemplateContent) {
                                                                    // setFixedTemplateVisible(true);
                                                                    let element = document.getElementById('template_div_id');
                                                                    if (element) {
                                                                        element.classList.remove('hideAutoFixedTemp');
                                                                    }
                                                                }
                                                                // player.controls(true);
                                                            }}></div>
                                                            {/* <button className='quizBtnnw' onClick={(event) => {manageQuizFieldData(event, object, index, quizIndexObj[object.id])}}>Continue</button> */}

                                                            {/* <button className='quizBtnnw' onClick={(event) => {manageQuizFieldData(event, object, index, quizIndexObj[object.id])}}>Submit</button> */}


                                                        </div>

                                                        : null
                                                    }
                                                    {String(object.isLabelVisible) === "1" ? <div id={`label${(object.id)}`} className={`${object.isBranching === 1 ? "player_dyn_img_label_branching" : "player_dyn_img_label"}`}>{object.label}</div> : null}
                                                </section>
                                            </div>

                                        </Draggable>
                                    )
                                })
                            }
                        </div>
                        <div>
                            {
                                contentMediaNew.map((object, index) => {
                                    const draggableProps = object.draggableProps === null ? "" : JSON.parse(object.draggableProps);
                                    return (
                                        <Draggable key={object.mediaType + object.id} handle="section" onDrag={handleDrag} grid={draggableProps.grid}
                                            cancel={draggableProps.cancel} defaultPosition={draggableProps.defaultPosition}
                                            bounds="video" {...dragHandlers}>
                                            <div id={`div_${object.mediaType}_${object.id}`} style={
                                                object.mediaType === "image"
                                                    ? { width: object.imageAreaWidth }
                                                    : object.mediaType === "TXT" ?
                                                        !object.useAsBtn ?
                                                            { width: draggableProps.width === 'auto' ? 'auto' : draggableProps.width + "px", height: draggableProps.height === 'auto' ? 'auto' : draggableProps.height + "px", background: "#fff", overflow: "auto" }
                                                            : null
                                                        : object.mediaType === "hotspot" ?
                                                            { resize: "none", overflow: "unset" }
                                                            : null
                                            }
                                                className={object.mediaType === "TXT" && object.useAsBtn ? "draggable_child_div_new text-button" : "draggable_child_div_new"}>
                                                <section>
                                                    {object.mediaType === "TXT" ?
                                                        <span style={{ justifyContent: "center", alignItems: "center" }}>
                                                            <div id={`text${object.id}`} className="text-container-r bg-light textPara" dangerouslySetInnerHTML={{ __html: object.textContent }} style={{ padding: "9px" }}>
                                                                {/* content inserted dynamically */}
                                                            </div>
                                                        </span>
                                                        : null
                                                    }
                                                    {object.mediaType === "hotspot" ?
                                                        <span style={{ padding: "0px", justifyContent: "center", display: "flex", alignItems: "center", cursor: isEditMode ? "all-scroll" : "pointer" }}>
                                                            <div className="shopping_02_cart_icon">
                                                                <img id={`icon${object.id}`} src={object.hotspotIconPath} />
                                                            </div>
                                                        </span>
                                                        : null
                                                    }
                                                    {object.mediaType === "image" ?
                                                        <div>
                                                            <div id={`floating_div_${object.mediaType}_${object.id}`} style={{ width: object.imageAreaWidth, background: "", position: "absolute" }}></div>
                                                            <img id={`image${(object.id)}`} className='player_dyn_img' src={object.imagePath} alt='logo-test' />
                                                        </div>
                                                        : null
                                                    }
                                                    {String(object.isLabelVisible) === "1" ? <div id={`label${(object.id)}`} className={`player_dyn_img_label`}>{object.label}</div> : null}
                                                </section>
                                            </div>

                                        </Draggable>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="panel_form" style={{ display: contentMediaSecHide }}>
                    <Tabs defaultActiveKey="interactions" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="interactions" title="Interactions">
                            {contentMediaSecHide === false ?
                                <>

                                    <input type="text" placeholder="Search here" className="search_bar config_poster_inp" style={{ width: "60%" }} />
                                    <div>
                                        {contentMedia ?
                                            contentMedia.map((object, index) => {
                                                // console.log(object);
                                                if (object.mediaType === "image") {
                                                    return (
                                                        <div key={index} className="form_options">
                                                            <div className="form_opt_images">
                                                                <img src="/assets/icon_images_2.png" alt="images" />
                                                                <span>
                                                                    {
                                                                        Math.abs(Number(object.startTime)) > 59 ?
                                                                            "0:" + (Math.abs(Number(object.startTime)) / 60).toFixed(0) + ":" + (((Math.abs(Number(object.startTime)) / 60) - (Math.abs(Number(object.startTime)) / 60).toFixed(0)) * 60).toFixed(0)
                                                                            : "0:0:" + Math.abs(Number(object.startTime))
                                                                    }
                                                                    {/* {
                                                                object.startTime > 0 ?
                                                                    Number(object.isPauseEnable) === 1 ?
                                                                        "0:0:" + (Number(object.pauseLength) / 1000)
                                                                        :
                                                                        (Math.abs(Number(object.startTime) - Number(object.endTime))) > 59
                                                                            ?
                                                                            "0:" + String((Math.abs(Number(object.startTime) - Number(object.endTime)) / 60).toFixed(2)).replace(".", ":")
                                                                            :
                                                                            "0:0:" + (Math.abs(Number(object.startTime) - Number(object.endTime)))
                                                                    : "0:0:0"
                                                            } */}
                                                                </span>
                                                            </div>
                                                            <div className="form_opt_images_txt">
                                                                <p>
                                                                    {object.label + " "}
                                                                    {Number(object.isBranching) === 1 ? <Badge>Branch</Badge> : null}
                                                                </p>
                                                                <span>{object.imageType.toUpperCase()}</span>
                                                                <button className='panelDownloadImg'>
                                                                    <a href={object.imagePath.startsWith("http") ? object.imagePath : `https://storage.googleapis.com/innovative-video/add_image_dir/${object.imagePath}`} target="_blank">
                                                                        Download Image
                                                                    </a>
                                                                </button>
                                                            </div>
                                                            <div className="form_opt_edit" style={{ display: "flex", alignItems: "center" }}>
                                                                {
                                                                    Number(object.startTime) <= 0 || Number(object.status) !== 1 ?
                                                                        <div className="d-flex justify-content-center bg-warning" style={{ writingMode: "vertical-rl", padding: "10px 0px", margin: "0 10px" }}>
                                                                            Draft
                                                                        </div>
                                                                        : null
                                                                }
                                                                <img src="/assets/icon_edit_2.png" onClick={() => edit(object, "image")} alt="edit" />
                                                                <img src="/assets/icon_delete_2.png" onClick={() => deleteImage(object)} alt="delete" />

                                                            </div>

                                                        </div>
                                                    )
                                                } else if (object.mediaType === "hotspot") {
                                                    return (
                                                        <div key={index} className="form_options">
                                                            <div className="form_opt_images">
                                                                <img src="/assets/icon_hotspot_2.png" alt="images" />
                                                                <span>
                                                                    {
                                                                        Math.abs(Number(object.startTime)) > 59 ?
                                                                            "0:" + (Math.abs(Number(object.startTime)) / 60).toFixed(0) + ":" + (((Math.abs(Number(object.startTime)) / 60) - (Math.abs(Number(object.startTime)) / 60).toFixed(0)) * 60).toFixed(0)
                                                                            : "0:0:" + Math.abs(Number(object.startTime))
                                                                    }
                                                                    {/* {
                                                                object.startTime > 0 ?
                                                                    Number(object.isPauseEnable) === 1 ?
                                                                        "0:0:" + (Number(object.pauseLength) / 1000)
                                                                        :
                                                                        (Math.abs(Number(object.startTime) - Number(object.endTime))) > 59
                                                                            ?
                                                                            "0:" + String((Math.abs(Number(object.startTime) - Number(object.endTime)) / 60).toFixed(2)).replace(".", ":")
                                                                            :
                                                                            "0:0:" + (Math.abs(Number(object.startTime) - Number(object.endTime)))
                                                                    : "0:0:0"
                                                            } */}
                                                                </span>
                                                            </div>
                                                            <div className="form_opt_images_txt">
                                                                <p>{object.label}</p>
                                                                <span>{object.mediaType.toUpperCase()}</span>
                                                                {/* <button>Switch Video: Kurti_Video</button> */}
                                                            </div>
                                                            <div className="form_opt_edit">
                                                                {
                                                                    Number(object.startTime) <= 0 || Number(object.status) !== 1 ?
                                                                        <div className="d-flex justify-content-center bg-warning" style={{ writingMode: "vertical-rl", padding: "10px 0px", margin: "0 10px" }}>
                                                                            Draft
                                                                        </div>
                                                                        : null
                                                                }
                                                                <img src="/assets/icon_edit_2.png" alt="edit" onClick={() => edit(object, "hotspot")} />
                                                                <img src="/assets/icon_delete_2.png" alt="delete" onClick={() => deleteHotspot(object)} />
                                                            </div>

                                                        </div>
                                                    )
                                                } else if (object.mediaType === "TXT") {
                                                    return (
                                                        <div key={index} className="form_options">
                                                            <div className="form_opt_images">
                                                                <img src="/assets/icon_text_2.png" alt="images" />
                                                                <span>
                                                                    {
                                                                        Math.abs(Number(object.startTime)) > 59 ?
                                                                            "0:" + (Math.abs(Number(object.startTime)) / 60).toFixed(0) + ":" + (((Math.abs(Number(object.startTime)) / 60) - (Math.abs(Number(object.startTime)) / 60).toFixed(0)) * 60).toFixed(0)
                                                                            : "0:0:" + Math.abs(Number(object.startTime))
                                                                    }
                                                                    {/* {
                                                                object.startTime > 0 ?
                                                                    Number(object.isPauseEnable) === 1 ?
                                                                        "0:0:" + (Number(object.pauseLength) / 1000)
                                                                        :
                                                                        (Math.abs(Number(object.startTime) - Number(object.endTime))) > 59
                                                                            ?
                                                                            "0:" + String((Math.abs(Number(object.startTime) - Number(object.endTime)) / 60).toFixed(2)).replace(".", ":")
                                                                            :
                                                                            "0:0:" + (Math.abs(Number(object.startTime) - Number(object.endTime)))
                                                                    : "0:0:0"
                                                            } */}
                                                                </span>
                                                            </div>
                                                            <div className="form_opt_images_txt">
                                                                <p>{object.label}</p>
                                                                <span>{object.mediaType}</span>
                                                            </div>
                                                            <div className="form_opt_edit">
                                                                {
                                                                    Number(object.startTime) <= 0 || Number(object.status) !== 1 ?
                                                                        <div className="d-flex justify-content-center bg-warning" style={{ writingMode: "vertical-rl", padding: "10px 0px", margin: "0 10px" }}>
                                                                            Draft
                                                                        </div>
                                                                        : null
                                                                }
                                                                <img src="/assets/icon_edit_2.png" onClick={() => edit(object, object.mediaType)} alt="edit" />
                                                                <img src="/assets/icon_delete_2.png" alt="delete" onClick={() => deleteText(object)} />
                                                            </div>

                                                        </div>
                                                    )
                                                } else if (object.mediaType === "Form") {
                                                    return (
                                                        <div key={index} className="form_options">
                                                            <div className="form_opt_images">
                                                                <img src="/assets/icon_form_1.png" alt="images" />
                                                                <span>
                                                                    {
                                                                        Math.abs(Number(object.startTime)) > 59 ?
                                                                            "0:" + (Math.abs(Number(object.startTime)) / 60).toFixed(0) + ":" + (((Math.abs(Number(object.startTime)) / 60) - (Math.abs(Number(object.startTime)) / 60).toFixed(0)) * 60).toFixed(0)
                                                                            : "0:0:" + Math.abs(Number(object.startTime))
                                                                    }
                                                                    {/* {
                                                                Number(object.isPauseEnable) === 1 ?
                                                                    "0:0:" + (Number(object.pauseLength) / 1000)
                                                                    :
                                                                    (Math.abs(Number(object.startTime) - Number(object.endTime))) > 59
                                                                        ?
                                                                        "0:" + String((Math.abs(Number(object.startTime) - Number(object.endTime)) / 60).toFixed(2)).replace(".", ":")
                                                                        :
                                                                        "0:0:" + (Math.abs(Number(object.startTime) - Number(object.endTime)))
                                                            } */}
                                                                </span>
                                                            </div>
                                                            <div className="form_opt_images_txt">
                                                                <p>{object.formTitle}</p>
                                                                {/* <p>{object.label}</p> */}
                                                                {/* <span>{object.type}</span> */}
                                                            </div>
                                                            <div className="form_opt_edit">
                                                                {
                                                                    Number(object.startTime) <= 0 || Number(object.status) !== 1 ?
                                                                        <div className="d-flex justify-content-center bg-warning" style={{ writingMode: "vertical-rl", padding: "10px 0px", margin: "0 10px" }}>
                                                                            Draft
                                                                        </div>
                                                                        : null
                                                                }
                                                                <img src="/assets/icon_edit_2.png" onClick={() => { redirectToQuizForm(object.id) }} alt="edit" />
                                                                <img src="/assets/icon_delete_2.png" alt="delete" onClick={() => deleteForm(object.id)} />
                                                            </div>
                                                        </div>
                                                    )
                                                }


                                            })
                                            : <center><span>No Data</span></center>}
                                    </div>
                                </>
                                :
                                <>
                                    {uploadType === "image" && <ImageForm closeForm={cancel} setImgFormData={getImgFormData} contentId={contentMediaId} isEdit={isEditMode} videoId={video_id} />}
                                    {uploadType === "hotspot" && <HotspotForm closeForm={cancel} setHotspotData={getHotspotData} contentId={contentMediaId} uploadType={uploadType} isEdit={isEditMode} videoId={video_id} />}
                                    {uploadType === "TXT" && <TextForm closeForm={cancel} setTextFormData={getTextFormData} contentId={contentMediaId} isEdit={isEditMode} videoId={video_id} />}
                                    {uploadType === "cta_settings" && <CtaSettings closeForm={cancel} setTextFormData={getTextFormData} contentId={contentMediaId} videoId={video_id} isEdit={isEditMode} deltaPosition={dragMode ? deltaPosition : { x: -1, y: -1 }} />}
                                    {uploadType === "template" && <Template closeForm={cancel} contentId={contentMediaId} videoId={video_id} isEdit={isEditMode} deltaPosition={deltaPosition} setUploadType={setUploadType} />}
                                    {uploadType === "branching" && <VideoBranchingForm closeForm={cancel} contentId={contentMediaId} videoId={video_id} isEdit={isEditMode} deltaPosition={dragMode ? deltaPosition : { x: -1, y: -1 }} />}
                                    {/* {uploadType === "modify-template" && <ModifyTemplate closeForm={cancel} contentId={contentMediaId} videoId={video_id} isEdit={isEditMode} deltaPosition={deltaPosition} setUploadType={setUploadType} />} */}
                                </>
                            }

                        </Tab>
                        {
                            playerVideoInfo ?
                                playerVideoInfo.mainVideo.parentVideoId <= 0
                                    ?
                                    <Tab eventKey="cloneVideos" title="Clone Videos" disabled={isEditMode || isToggalMode ? true : false}>
                                        {/* <Card className='m-3 p-3'> */}
                                        <VideoCloneList videoName={playerVideoInfo ? playerVideoInfo.mainVideo.label : ""} videoId={video_id} />
                                        {/* </Card> */}
                                    </Tab>
                                    : null
                                : null
                        }

                    </Tabs>

                </div>
            </div>

            {/* Popup and modals*/}
            {/* upload image modal */}
            <div>
                <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title> <strong>Upload</strong> </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UploadFile isMultiple="false" dataReturnType={"base64EncodedImg"} type={uploadType} setFormData={getFormData} />
                    </Modal.Body>
                </Modal>
            </div>
            {/* <div>
                <Modal isOpen={uploadImgModal} toggle={() => uploadImgToggle("")}>
                    <ModalHeader toggle={() => uploadImgToggle("")}>
                        <strong>Upload</strong>
                    </ModalHeader>
                    <ModalBody>
                        <UploadFile isMultiple="false" dataReturnType={"base64EncodedImg"} type={uploadType} setFormData={getFormData} />
                    </ModalBody>
                </Modal>
            </div> */}
        </Fragment >
    )
}

export default Player;