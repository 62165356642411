import React, { Fragment, useEffect, useState } from "react";
import './videoConfigForm.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate, useLocation } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import fileProcess from "../../../Common/Image_Processing/fileProcess";

import HttpService from '../../../Common/httpServices/http.service';
import { Badge, Button, Modal, Tab, Tabs } from "react-bootstrap";
import fileUtils from "../../../utils/file-utils/fileUtils";
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import validator from "validator";

var video_id;

const VideoConfigForm = () => {
    const [files, setFiles] = useState("");
    const [videoFile, setVideoFile] = useState("");
    const [formValues, setFormValues] = useState({});
    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [playerSizeVal, setPlayerSizeVal] = useState("");

    let navigate = useNavigate();
    let location = useLocation();

    try {
        if (location.state.id) {
            video_id = location.state.id;
        } else {
            navigate("/");
        }
    } catch (err) {
        navigate("/");
    }

    const handleClose = () => {
        setShow(false);
    }

    const handleShow = () => {
        setShow(true);
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    async function onChangeUploadedImages(file) {
        const base64PosterImg = await fileProcess.manageUploads(file);
        setFiles(base64PosterImg.base64EncodedImg);
    }

    function onChangeUploadedVideo(file) {
        setVideoFile(file);
        console.log(file);
        // let formData = new FormData();
        // formData.append(`uploadFiles`, file);
    }

    const onChnagePosterUrl = (e) => {
        setFiles(e.target.value);
    }

    const onChangeVideoUrl = (e) => {
        setVideoFile(e.target.value);
    }

    async function updateMainVideoConfig() {
        setLoader(true);
        // if (formValues.label && files) { // commented by Pritesh to make this non-mandatory
        if (formValues.label) {

            // check valid url
            if (files && !files.startsWith("data:image")) {
                if (!validator.isURL(files)) {
                    alert("Please enter a valid URL");
                    setLoader(false);
                    return;
                }
            }

            // set data
            formValues.posterImagePath = files ? files : "";
            let formData = new FormData();

            if (typeof videoFile === "object") {
                formData.append(`uploadFiles`, videoFile);
            } else {
                formValues.videoPath = videoFile;
            }
            formData.append(`mainVideo`, JSON.stringify(formValues));

            debugger
            let response = null;
            try {
                // response = await HttpService.PUT("/private/update/main-video", video_id, formValues);
                response = await HttpService.PUT("/private/update/main-video", video_id, formData);
                console.log(response);
            } catch (err) {
                isSessionExpire();
            }

            if (response.data.status == "SUCCESS") {
                alert("Saved Successfully")
                redirectTo("/player");
                // navigate("/player", { state: { id: video_id } }) 
                // window.location.href = `/player/${video_id}`; 
            } else {
                console.log("Not Update!!!");
            }

        } else {
            alert("Please fill (*) required fields !!!");
        }

        setLoader(false);
    }

    const redirectTo = (redireactUrlOrRoute) => {
        // navigate(redireactUrlOrRoute, { state: { id: video_id } })
        window.location.href = `${redireactUrlOrRoute}/${video_id}`;
    }

    async function getVideoConfigByID(id) {
        try {
            const response = await HttpService.GET("/private/main-video/" + video_id);
            const getByIdMainVideo = response.data.object;

            if (response.data.status === 'SUCCESS') {
                // formValues.sizeaaa = String(`${getByIdMainVideo.mainVideo.playerWidth}*${getByIdMainVideo.mainVideo.playerHeight}`);
                setFormValues(getByIdMainVideo.mainVideo);
                // setPlayerSizeVal(String(`${getByIdMainVideo.mainVideo.playerWidth}*${getByIdMainVideo.mainVideo.playerHeight}`))
                setFiles(getByIdMainVideo.mainVideo.posterImagePath)
                setVideoFile(getByIdMainVideo.mainVideo.videoPath);
            }
            return response;
        } catch (err) {
            isSessionExpire();
        }
    }

    const handelHeightWidth = (e) => {
        const { name, value } = e.target;
        let slotArray = value.split('*');
        setFormValues({
            ...formValues,
            'playerWidth': slotArray[0],
            'playerHeight': slotArray[1],
        });
        return true;
    }

    const handelHeightWidthCustom = (e) => {
        const { name, value } = e.target;

        setFormValues({
            ...formValues,
            [name]: value,
        });
    }

    useEffect(() => {
        const id = video_id;
        getVideoConfigByID(id);
    }, []);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="vd_config">
                    <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                        <Col md={12} style={{ width: "auto" }}> <h2>Video Configuration Setting <Badge bg="primary">{video_id}</Badge></h2> </Col>
                    </Row>
                    <div className="hotspot_position_1">
                        <div className="video_config">
                            <Row>
                                <Col md={3}><h4>Video Playback</h4></Col>
                                <Col md={9}>
                                    <select name='videoRenderType' value={formValues.videoRenderType} onChange={handleInputChange}>
                                        <option value="">--Select an option--</option>
                                        <option value="0">Default</option>
                                        <option value="1">Auto Play</option>
                                    </select>
                                </Col>
                            </Row>
                            {/* {
                                String(formValues.videoRenderType) === "1" ?
                                    <Row>
                                        <Col md={3}><h4>Video Play Type</h4></Col>
                                        <Col md={9}>
                                            <select name='viewType' value={formValues.viewType} onChange={handleInputChange}>
                                                <option value="">--Select an option--</option>
                                                <option value="1">In View (%)</option>
                                                <option value="2">When Out of View</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    : null
                            } */}
                            <Row>
                                <Col md={3}><h4>In View (%)</h4></Col>
                                <Col md={9}><input name="inView" value={formValues.inView} onChange={handleInputChange} type="text" id="poster_img" className="config_poster_inp" placeholder="Enter percent here" />
                                </Col>
                            </Row>
                            {/* {
                                (String(formValues.videoRenderType) === "1") ?
                                    : null
                            } */}
                            <Row>
                                <Col md={3}><h4>Out Of View</h4></Col>
                                <Col md={9}>
                                    <select name='outOfView' value={formValues.outOfView} onChange={handleInputChange}>
                                        <option value="">--Select an option--</option>
                                        <option value="1">Keep Playing</option>
                                        <option value="0">Pause</option>
                                    </select>
                                </Col>
                            </Row>
                            {/* {
                                (String(formValues.videoRenderType) === "1") ?
                                    : null
                            } */}
                        </div>
                        <Row>
                            <Col md={3}><h4>Video Label *</h4></Col>
                            <Col>
                                <input type="text" onChange={handleInputChange} value={formValues.label} name="label" placeholder="Enter Video Label" className='config_poster_inp' style={{ width: '51%' }} />
                                {/* <input type="text" onChange={(e) => setVideoLabel(e.target.value)} value={videoLabel} id="videoLabel" name="video_label" placeholder="Enter Video Label" className='config_poster_inp' style={{ width: '70%' }} /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}><h4>Upload Poster Image</h4></Col>
                            <Col md={9}>
                                <Tabs defaultActiveKey={`enter_url`} id="video_tabs" className="mb-3 tabs_news">
                                    <Tab eventKey={`upload_new_image}`} title={`Upload Poster Image`}>
                                        <FileUploader multiple={false} handleChange={onChangeUploadedImages} name="config_poster-files" id="config_poster-files" types={fileUtils.getFileTypes("image")} />
                                        <p>{files ? <span>file selected.</span> : "select poster image for upload."}</p>
                                    </Tab>
                                    <Tab eventKey="enter_url" title="Enter URL">
                                        <div className="enter_url">
                                            <input onChange={onChnagePosterUrl} value={files} name="posterImagePath" type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Image Url" />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div className="pb-4">
                                    {/* https://storage.googleapis.com/innovative-video/add_poster_image_dir/ */}
                                    {files ?
                                        <img className="poster_image_view" src={files} width="100" alt="poster_image_view" />
                                        : <img width="100" src={"https://storage.googleapis.com/innovative-video/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}><h4>Edit Video Url</h4></Col>
                            <Col md={9}>
                                <Tabs defaultActiveKey={`enter_url`} id="video_tabs" className="mb-3 tabs_news">
                                    <Tab eventKey={`upload_edit_video}`} title={`Upload Video`}>
                                        <FileUploader multiple={false} handleChange={onChangeUploadedVideo} name="change_video_url" id="change_video_url" types={fileUtils.getFileTypes("video")} />
                                        <p>{files ? <span>file selected.</span> : "select poster video for upload."}</p>
                                    </Tab>
                                    <Tab eventKey="enter_url" title="Enter URL">
                                        <div className="enter_url">
                                            <input onChange={onChangeVideoUrl} value={videoFile} name="change_video_url" type="text" id="change_video_url" className="config_poster_inp" placeholder="Enter Image Url" />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div className="pb-4">
                                    {
                                        videoFile ?
                                            typeof videoFile !== "object" ?
                                                <Button onClick={handleShow}>Video Preview</Button>
                                                : null
                                            : null
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={3}><h4>Video Controls</h4></Col>
                            <Col md={9}>
                                <select name='videoControls' className="keepplaying_1" value={formValues.videoControls} onChange={handleInputChange}>
                                    <option value="">--Select an option--</option>
                                    <option value="1">ON</option>
                                    <option value="0">OFF</option>
                                </select>
                            </Col>
                        </Row>
                        {
                            String(formValues.videoControls) === "1" ?
                                <Row>
                                    <Col md={3}><h4>Seekbar Controls</h4></Col>
                                    <Col md={9}>
                                        <select name='seekbarControls' className="keepplaying_1" value={formValues.seekbarControls} onChange={handleInputChange}>
                                            <option value="">--Select an option--</option>
                                            <option value="1">Enable</option>
                                            <option value="0">Disable</option>
                                        </select>
                                    </Col>
                                </Row>
                                : null
                        }
                        <Row>
                            <Col md={3}><h4>TrueReach Site Guid</h4></Col>
                            <Col md={9}>
                                <input type="text" id="tr_site_guid" name="truereachSiteGuid" className="config_poster_inp" placeholder="Enter TrueReach site guid here"
                                    value={formValues.truereachSiteGuid}
                                    onChange={handleInputChange} />
                            </Col>
                        </Row>

                         <div className="video_config">
                            <Row>
                                <Col md={3}><h4>Player Size</h4></Col>
                                <Col md={9}>
                                    <select name='slotSize' value={`${formValues.playerWidth}*${formValues.playerHeight}`} onChange={handelHeightWidth}>
                                        <option value="">--Select an option--</option>
                                        <option value="640*360">640*360</option>
                                        <option value="1280*720">1280*720</option>
                                        <option value="1440*1080">1440*1080</option>
                                        <option value="1920*1080">1920*1080</option>
                                        <option value="360*640">360*640</option>
                                        <option value="720*1280">720*1280</option>
                                        <option value="1080*1440">1080*1440</option>
                                        <option value="1080*1920">1080*1920</option>
                                        <option value="300*250">300*250</option>
                                        <option value="336*280">336*280</option>
                                        <option value="1200*627">1200*627</option>
                                        <option value="1200*628">1200*628</option>
                                        <option value="627*1200">627*1200</option>
                                        <option value="628*1200">628*1200</option>
                                        <option value="600*600">600*600</option>
                                        <option value="720*720">720*720</option>
                                        <option value="1080*1080">1080*1080</option>
                                        <option value="1920*1920">1920*1920</option>
                                        <option value="360*450">360*450</option>
                                        <option value="320*480">320*480</option>
                                        <option value="480*320">480*320</option>
                                        <option value="1024*768">1024*768</option>
                                        <option value="768*1024">768*1024</option>
                                        <option value="custom">Custom Size</option>
                                    </select>
                                </Col>
                            </Row>
                            {
                                (String(formValues.playerWidth) === "custom") ?
                                    <Row>
                                        <Col md={3}><h4>Player Width (in px)</h4></Col>
                                        <Col md={9}><input name="playerWidth" value={""} onChange={handelHeightWidthCustom} type="text" id="poster_img" className="config_poster_inp" placeholder="Enter number here" />
                                        </Col>
                                    </Row>
                                    : null
                            }
                            {
                                (String(formValues.playerWidth) === "custom") ?
                                    <Row>
                                        <Col md={3}><h4>Player Height (in px)</h4></Col>
                                        <Col md={9}><input name="playerHeight" value={""} onChange={handelHeightWidthCustom} type="text" id="poster_img" className="config_poster_inp" placeholder="Enter number here" />
                                        </Col>
                                    </Row>
                                    : null
                            }

                        </div> 


                        

                        {/* <Row>
                            <Col md={3}><h4>Landing Page</h4></Col>
                            <Col md={9}>
                                <input type="text" id="landing_page" name="landingPage" className="config_poster_inp" placeholder="Enter Landing Page"
                                    value={formValues.landingPage}
                                    onChange={handleInputChange} />
                            </Col>
                        </Row> */}

                        <Row>
                            <Col md={12}>
                                <button className="btn_save" onClick={updateMainVideoConfig}>Save</button>
                                <button className="btn_cancel" onClick={() => redirectTo("/player")}>Cancel</button>
                                {/* <button className="btn_cancel" onClick={() => redirectTo("/player")}>Skip</button> */}
                            </Col>
                        </Row>
                    </div>
                </div>
            </Container>
            {/* Models */}
            <Modal show={show} onHide={handleClose} className="videoPreview">
                <Modal.Header closeButton>
                    <Modal.Title>Video Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <video id="preview-player" preload="auto" width="340" height="60" poster="" autoPlay={false} controls data-setup='{ }'>
                        <source id="source" src={videoFile} type="video/mp4" />
                    </video>
                </Modal.Body>
            </Modal>
        </Fragment>
    )
}
export default VideoConfigForm;