import React from 'react';
import { ColorRing } from 'react-loader-spinner';
import './loader.css';

const Loader = () => {
    return (
        <>
            <div className='loader_container' style={{ background: "#043348", opacity: "1" }}>
                <div className='inner_container'>
                    <ColorRing
                        visible={true}
                        height="110"
                        width="110"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                    />
                    <div className='d-flex justify-content-center'>
                        <span style={{ marginRight: "5px", fontSize: "20px", color: "gray" }}>
                            {/* <strong>L o a d i n g</strong> */}
                            <strong className='text-light'>Please wait</strong>
                        </span>
                        {/* <ThreeDots height="35" width="35" radius="9"
                            color="gray" ariaLabel="three-dots-loading"
                            wrapperStyle={{}} wrapperClassName="" visible={true}
                        /> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Loader;