import React, { useState } from "react";
import AppRoutes from "./AppRoutes";

// Extrnal css for react-bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <AppRoutes />
  );
}

export default App;
