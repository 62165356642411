import React, { useState } from 'react';
import { FileUploader } from "react-drag-drop-files";
import FileUtils from "../../utils/file-utils/fileUtils";
import { useNavigate } from "react-router-dom";
import FileProcess from '../../Common/Image_Processing/fileProcess';
import { Tab, Tabs } from 'react-bootstrap';
import validator from 'validator';
import "./uploadFile.css";

const UploadFile = (props) => {
    // states
    const [files, setFiles] = useState("");
    const [url, setUrl] = useState("");

    const navigate = useNavigate();

    function onChangeUploadedVideos(file) {
        debugger
        if (file.name.length < 95) {
            setFiles(file);
        } else {
            alert(`${props.type} name is too long please rename and try again !!!`);
            return false;
        }
    }

    const submitForm = async (event, submitType) => {
        event.preventDefault();
        if (files === null && url === "") {
            console.log("no files to submit");
            return;
        }

        if (submitType === "UPLOAD") {
            if (files) {
                if (props.dataReturnType === "base64EncodedImg") {
                    const data = await FileProcess.manageUploads(files);
                    props.setFormData(data);
                } else if (props.dataReturnType === "formData") {
                    // creare FormData for multipart
                    const formData = new FormData();

                    if (JSON.parse(props.isMultiple)) {
                        // append each file into formData object
                        for (let i = 0; i < files.length; i++) {
                            formData.append(`uploadFiles`, files[i]);
                        }
                    } else {
                        formData.append('uploadFiles', files);
                    }
                    props.setFormData({ type: submitType, formData: formData }); // return formData to parent component
                    // alert("Saved Successfully");
                }
            } else {
                alert("Please select file to upload");
            }
        } else if (submitType === "URL") {
            if (validator.isURL(url) && url.length <= 250) {
                props.setFormData({ type: submitType, formData: url });
                // alert("Saved Successfully");
            } else {
                alert('Please Enter Valid URL or url length must be less than 250 !!!')
            }
        }

    };

    function onChangeUrl(e) {
        // debugger
        let url = e.target.value;
        // let lengthUrl= url.length;
        // if(lengthUrl < 250){

        // }
        setUrl(url);
    }

    return (
        <>
            <Tabs defaultActiveKey={`upload`} id="video_tabs" className="mb-3 tabs_news">
                <Tab eventKey={`upload`} title={`UPLOAD NEW ${props.type.toUpperCase()}`}>
                    <FileUploader multiple={JSON.parse(props.isMultiple)} handleChange={onChangeUploadedVideos} name="video-files" id="video-files" types={FileUtils.getFileTypes(props.type)} />
                    <p>{files ? <span><strong>{`'${files.name}'`}</strong> file selected.</span> : "select " + props.type + " for upload."}</p>
                    <button className='btn btn-primary m-2' onClick={(e) => submitForm(e, "UPLOAD")}>Submit</button>
                </Tab>
                <Tab eventKey="enter_url" title="ENTER URL">
                    <div className="enter_url">
                        <input type="text" name="enter_url" onChange={onChangeUrl} value={url} placeholder="https://example.com" required />
                        <button className='btn btn-primary m-2' onClick={(e) => submitForm(e, "URL")}>Submit</button>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
}
export default UploadFile;


// Important NOTE
// ==========================
// dataReturnType are which format you want to get data from form.
// There have 2 type of dataReturnType.
// 1. "base64EncodedImg"
// 2. "formData"