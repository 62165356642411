import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HttpService from '../../Common/httpServices/http.service';
import { isSessionExpire } from '../authentication/jwt/manageSession';
import "./header.css";

const Header = (props) => {
    // const [mainVideoList, setMainVideoList] = useState([]);
    const navigate = useNavigate();

    function logoutUser() {
        setTimeout(() => {
            // sessionStorage.clear();
            // sessionStorage.removeItem("token");
            alert("session exipred");
        }, 6000)
        alert("you are logged out successfully...");
        sessionStorage.clear();
        sessionStorage.removeItem("token");
        window.location.href = "/";
    }

    // const getMainVideoList = async (pageNo, pageSize, videoName) => {
    //     try {
    //         const response = await HttpService.GET(`/private/main-video/list?page-number=${pageNo}&page-size=${pageSize}&video-name=${videoName}`);
    //         setMainVideoList(response.data.object.content);
    //     } catch (err) {
    //  //isSessionExpire();
    // }
    // }

    useEffect(() => {
        if (sessionStorage.getItem("token")) {
            // getMainVideoList(0, 10, "");
        }
    }, []);
    // console.log(mainVideoList);
    return (
        <Fragment>
            <div className='panel_header'>
                <Link to={"/dashboard"}><img src="/assets/logo.png" alt='Momagic' /></Link>
                {
                    sessionStorage.getItem("token") ?
                        <div className="panel_menu">
                            <ul>
                                <li className='headUserName'><img src="/assets/user-icon.png" /><button className='btn'>{sessionStorage.getItem("userId")}</button></li>
                                <li><Link to={"/dashboard"}><button className='btn'>Home</button></Link></li>
                                {/* <li><Link to={"/dashboard"}>New</Link></li> */}
                                {/* <li className='sunmenu_li'><a href="#">Videos <img src="/assets/drop_down.png" /></a>
                                    <div className='submenu'>
                                        <input type="text" placeholder="Search video" className="search_video_header" />
                                        <a href="#">All Videos</a>
                                        {mainVideoList.map((object, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <a href="#">{object.videoName}</a>
                                                </Fragment>
                                            )
                                        })}
                                    </div>
                                </li> */}
                                {/* <li><a href="#">Reporting</a></li> */}
                                <li className='sunmenu_li text-light'>
                                    {/* More <img src="/assets/drop_down.png" /> */}
                                    {/* <div className='submenu submenu_li_1'> */}
                                    {/* <Link to={"/"}>Login</Link> */}
                                    {/* <Link to={"/video-branching"}>Video Branching</Link>
                                <Link to={"/video-interaction"}>Video Interaction</Link> */}
                                    <button className='btn' onClick={logoutUser}>Logout</button>
                                    {/* </div> */}
                                </li>
                            </ul>
                        </div>
                        : null
                }
            </div>
        </Fragment>
    );
}

export default Header;