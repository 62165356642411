import React from 'react';
import { BrowserRouter as Router, Route, useRoutes, Routes } from "react-router-dom";

// layout file's
import Dashboard from './Components/dashboard/dashboard';
import Player from './Components/player/player';
import InstreamPlayer from './Components/instream-player/instream-player';
import Header from './Components/header/header';
import Footer from './Components/footer/footer';
import VideoConfigForm from './Components/forms/video-config-form/videoConfigForm';
import VideoBranchingForm from './Components/forms/video-branching-form/videoBranchingForm';
import VideoInteractionForm from './Components/forms/video-interaction-form/videoInteractionForm';
import Login from './Components/authentication/login/login';
import Quiz_feedback from './Components/forms/quiz-form/Quiz_feedback';
import VideoTagGenerationForm from './Components/forms/video-tag-generation/videoTagGeneration';
import DefaultTemplates from './Components/forms/template/common/defaultTemplates';
import AdsForm from './Components/forms/ads-form/adsForm';
import TemplateInteraction from './Components/forms/template/template-interaction/templateInteraction';
import Error404 from './Common/error-pages/404-error/Error404';
import Error401 from './Common/error-pages/401-error/Error401';
import ModifyTemplate from './Components/forms/modify-template/modifyTemplate';

function AppRoutes(props) {
    // remove session when not switching
    sessionStorage.removeItem("switchVideoId");


    // function Routes() {
    //     const routes = useRoutes([
    //         { path: '/login', element: <Login /> },
    //         { path: '/dashboard', element: <Dashboard /> },
    //         { path: '/player', element: <Player /> },
    //         { path: '*', element: <><center className="text-danger">Route NOT FOUND</center></> }
    //     ]);
    //     return routes;
    // }

    // function Routes() {
    //     let routes = useRoutes([
    //         {
    //             path: "/",
    //             element: <Login />,
    //             children: [
    //                 {
    //                     path: "login"
    //                 }
    //             ],
    //         },
    //         { path: "bashbo", element: <Dashboard /> },
    //     ]);
    //     return routes;
    // }

    return (
        <Router>
            <Header />
            <Routes path="/">
                {/* <Route index element={sessionStorage.getItem("token") ? <Dashboard /> : <Login />} /> */}
                <Route index element={sessionStorage.getItem("token") ? <Dashboard /> : <Login />} />
                <Route path="/dashboard" element={sessionStorage.getItem("token") ? <Dashboard /> : <Login />} />
                <Route path="/player/:main_video_id" element={sessionStorage.getItem("token") ? <Player /> : <Login />} />
                <Route path="/instream-player/:main_video_id" element={sessionStorage.getItem("token") ? <InstreamPlayer /> : <Login />} />
                <Route path="/video-config" element={sessionStorage.getItem("token") ? <VideoConfigForm /> : <Login />} />
                <Route path="/video-branching" element={sessionStorage.getItem("token") ? <VideoBranchingForm /> : <Login />} />
                <Route path="/video-interaction" element={sessionStorage.getItem("token") ? <VideoInteractionForm /> : <Login />} />
                <Route path="/quiz-form/:main_video_id" element={sessionStorage.getItem("token") ? <Quiz_feedback /> : <Login />} />
                <Route path="/video-tag-generation" element={sessionStorage.getItem("token") ? <VideoTagGenerationForm /> : <Login />} />
                <Route path='/template/list' element={sessionStorage.getItem("token") ? <DefaultTemplates /> : <Login />} />
                <Route path='/template/interaction' element={sessionStorage.getItem("token") ? <TemplateInteraction /> : <Login />} />
                <Route path='/template/modifiy' element={sessionStorage.getItem("token") ? <ModifyTemplate /> : <Login />} />
                <Route path='/ads-form' element={sessionStorage.getItem("token") ? <AdsForm /> : <Login />} />
                <Route path="*" element={<Error404 />} />
                <Route path="/error/404" element={<Error404 />} />
                <Route path="/error/401" element={<Error401 />} />
            </Routes>
            <Footer />
        </Router>
    );
}
// //   { path: '/login', element: <Login /> },
// { path: '/dashboard', element: <Dashboard /> },
// { path: '/player', element: <Player /> },
// { path: '*', element: <><center className="text-danger">Route NOT FOUND</center></> }
export default AppRoutes;
