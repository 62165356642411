class Helper {
    afterDecimal = (numaricValue) => {
        const value = String(numaricValue).split(".")[1];
        return value;
    };

    beforeDecimal = (numaricValue) => {
        const value = String(numaricValue).split(".")[0];
        return value;
    };

    getFileNameFromURL = (url) => {
        var index = url.lastIndexOf("/") + 1;
        var filename = url.substr(index);
        return filename;
    }

    getExtensionFromURL(url) {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }

    // getSortedArrayList(arrayList, sortBy) {

    //     let sortedData = arrayList.sort(
    //         (objectA, objectB) => {
    //             parseFloat(objectA + ".sortBy") - parseFloat(objectB + ".sortBy")
    //         }
    //     );

    //     return sortedData;

    // }

    getBaseName() {
        var prefix = "/";
        return `${prefix}interactive-video-admin`;
    }
}

export default new Helper();