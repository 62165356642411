import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

const CommonPagination = (props) => {
    let properties = props.paginationProps;

    const totalPagesArray = [];
    for (let i = 0; i < properties.totalPages; i++) {
        totalPagesArray.push(i)
    }

    function perPageRequestFetch(pageNo, pageSize, videoName) {
        props.setData(pageNo, pageSize, videoName)
    }

    return (
        <>
            {properties ?
                <Pagination>
                    {properties.pageNo > 0 ?
                        <>
                            <Pagination.First onClick={() => perPageRequestFetch(0, properties.pageSize, "")} />
                            <Pagination.Prev onClick={() => perPageRequestFetch((properties.pageNo - 1), properties.pageSize, "")} />
                            <Pagination.Ellipsis />
                        </>
                        : null
                    }

                    {
                        totalPagesArray.map((pageNo, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {properties.pageNo > (pageNo - 2) && properties.pageNo < (pageNo + 2) ?
                                        <Pagination.Item
                                            onClick={() => perPageRequestFetch(pageNo, properties.pageSize, "")}
                                            active={properties.pageNo === pageNo ? "active" : null}>
                                            {pageNo + 1}
                                        </Pagination.Item>
                                        : null}
                                </React.Fragment>
                            )
                        })
                    }
                    {properties.pageNo < (properties.totalPages - 1) ?
                        <>
                            <Pagination.Ellipsis />
                            <Pagination.Next onClick={() => perPageRequestFetch((properties.pageNo + 1), properties.pageSize, "")} />
                            <Pagination.Last onClick={() => perPageRequestFetch((properties.totalPages - 1), properties.pageSize, "")} />
                        </>
                        : null
                    }
                    <Pagination.Item>
                        Total Items <Badge color="secondary">{properties.totalItems}</Badge>
                    </Pagination.Item>
                    <Pagination.Item>
                        Page Items <Badge color="secondary">{properties.totalNoItem}</Badge>
                    </Pagination.Item>
                    <Pagination.Item>
                        Pages <Badge color="secondary">{properties.totalPages}</Badge>
                    </Pagination.Item>
                    {/* <Pagination.Item>
                        Page Size <Badge color="secondary">{properties.pageSize}</Badge>
                    </Pagination.Item> */}
                </Pagination>
                : null}
        </>
    );
}

export default CommonPagination;