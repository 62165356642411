
function getVideoSizes() {
    const videoSizes = [
        // "640*360",
        "1280*720",
        "1440*1080",
        "1920*1080",
        "360*640",
        "720*1280",
        "1080*1440",
        "1080*1920",
        "300*250",
        "336*280",
        "1200*627",
        "1200*628",
        "627*1200",
        "628*1200",
        "600*600",
        "720*720",
        "1080*1080",
        "1920*1920",
        "360*450",
        "320*480",
        "480*320",
        "1024*768",
        "768*1024"];
    return videoSizes;
}

function extractVideoSize(videoSize) {
    let extract = videoSize.split("*");
    return { width: extract[0], height: extract[1] };
}

export { getVideoSizes, extractVideoSize };