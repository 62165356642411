import React, { Fragment, useState, useEffect } from "react";
import "./template.css"
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import httpService from "../../../Common/httpServices/http.service";
import { Button, Col, Container, Table } from "react-bootstrap";
import DefaultTemplates from "./common/defaultTemplates";
import { Link } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";

const Template = (props) => {
    const [videoId, setVideoId] = useState("");
    const [videoTemplateList, setVideoTemplateList] = useState();

    const getVideoTemplateListById = async (videoId) => {
        let response = null;
        try {
            response = await httpService.GET_BY_ID("/private/video/template/list", videoId);
            if (response.data.status === "SUCCESS") {
                setVideoTemplateList(response.data.object);
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const redirectTo = () => {
        window.location.href = `/player/${videoId}`;
    }

    const redirectToSamePage = () => {
        window.location.href = `/player/${videoId}&template=1`;
    }

    const makeAsFixed = async (e, object) => {
        //console.log(videoId,object) 
        let status = window.confirm("Do you want to make this template as fixed on Video?");
        if (status === true) {
            try {
                const response = await httpService.PUT("/private/video/template/fixedTemplate", `${object.videoId}/${object.id}`, {});
                if (response.data.status === "SUCCESS") {
                    getVideoTemplateListById(videoId);
                    redirectToSamePage();
                }
            } catch (error) {
                getVideoTemplateListById(videoId);
                //isSessionExpire();
            }
        }
    }

    const removeAsFixed = async (e, object) => {
        //console.log(videoId,object) 
        let status = window.confirm("Do you want to make this template remove from Video?");
        if (status === true) {
            try {
                const response = await httpService.PUT("/private/video/template/fixedTemplate", `${object.videoId}/0`, {});
                if (response.data.status === "SUCCESS") {
                    getVideoTemplateListById(videoId);
                    alert("removed");
                    redirectToSamePage();
                }
            } catch (error) {
                getVideoTemplateListById(videoId);
                //isSessionExpire();
            }
        }
    }

    const deleteTemplateById = async (e, object) => {
        debugger
        let status = window.confirm("Do you want to delete this template ?");
        if (status === true) {
            try {
                const response = await httpService.DELETE_BY_ID(`/private/video/template/delete/${object.id}`, object.videoId);
                if (response.data.status === "SUCCESS") {
                    getVideoTemplateListById(videoId);
                    redirectToSamePage();
                } else if (response.data.status === "ALREADY_USED") {
                    // alert(response.data.message);
                    alert("Template used in branching already you can't delete it !!!");
                }
            } catch (error) {
                getVideoTemplateListById(videoId);
                //isSessionExpire();
            }
        }
    }

    const modifyTemplate = function () {
        try {
            props.setUploadType('modify-template');
        } catch (e) { console.log(e); }
    }

    useEffect(() => {
        let videoId = props.videoId;
        setVideoId(videoId);
        // call methods
        getVideoTemplateListById(videoId);
    }, []);

    console.log(videoTemplateList);

    return (
        <Fragment>
            <div id="edit_modal">
                <div className="edit_modal_inner">

                    {/* <h2>Video Templates</h2> */}
                    {/* <input type="text" placeholder="Search here" className="search_bar config_poster_inp" /> */}
                    <div className="btn_div">
                        <Link to={"/template/list"} state={{ videoId: videoId }}>
                            <Button className="btn btn-primary">
                                Create Template
                            </Button>
                        </Link>
                    </div>

                    <div>
                        {
                            videoTemplateList ?
                                videoTemplateList.map((object, index) => {
                                    return (
                                        <div key={index} className="form_options">
                                            <div className="form_opt_images">
                                                <img src="/assets/template.png" alt="images" />
                                            </div>
                                            <div className="form_opt_images_txt">
                                                <p>{object.templateName}</p>
                                                <p>{object.templateType === 1 ? <button className='panelDownloadImg' onClick={(e) => removeAsFixed(e, object)}>Remove Template</button> : <button className='panelDownloadImg' onClick={(e) => makeAsFixed(e, object)}>Make As Fixed</button>}</p>
                                            </div>
                                            <div className="form_opt_edit">
                                                {/* <img src="/assets/icon_edit_2.png" alt="edit" />
                                        <img src="/assets/icon_delete_2.png" alt="delete" /> */}
                                                {/* <div onClick={modifyTemplate}><img src="/assets/icon_edit_2.png" alt="Modify Template" title="Modify Template" /></div> */}
                                                <Link to={"/template/modifiy"} state={{ videoId: videoId, templateId: object.id }}><img src="/assets/icon_edit_2.png" alt="Modify Template" title="Modify Template" /></Link>
                                                <Link to={"/template/interaction"} state={{ videoId: videoId, templateId: object.id }}><img src="/assets/icon_copy_2.png" alt="Map Interactions" title="Map Interactions" /></Link>
                                                <Link onClick={(e) => deleteTemplateById(e, object)} state={{ videoId: videoId, templateId: object.id }}><img src="/assets/icon_delete_2.png" alt="delete template" title="Delete Template" /></Link>
                                            </div>
                                        </div>
                                    )
                                })

                                : "Please create a template !!!"
                        }
                    </div>

                    <div className="col-md-12">
                        <button className="btn_cancel" onClick={redirectTo}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Fragment >
    )
}

export default Template;

