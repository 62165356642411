import React, { Fragment, useState, useRef, useEffect } from "react";
import './cta-settings.css';
import TextEditor from "../../../Common/editor/editor";
import { Col, Row } from "react-bootstrap";
import videojs from 'video.js';
import httpService from "../../../Common/httpServices/http.service";
import { isSessionExpire } from "../../authentication/jwt/manageSession";
import Loader from "../../../Common/loader/loder";
import validator from "validator";
import { Tab, Tabs } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import fileUtils from "../../../utils/file-utils/fileUtils";
import fileProcess from "../../../Common/Image_Processing/fileProcess";

const CtaSettings = (props) => {
    const [player, setPlayer] = useState("");
    const [contentMediaId, setContentMediaId] = useState("");
    const [loader, setLoader] = useState(false);
    const [videoId, setVideoId] = useState(0)
    const [ctaSettings, setCtaSettings] = useState({
        landingPage: "",
        ctaContent: "",
        draggableProps: ""
    });
    const [files, setFiles] = useState("");

    const handleInputChangeCheckbox = (e) => {
        const { name, checked } = e.target;

        // set value of any checkbox
        if (checked !== undefined) {
            console.log(checked);
            setCtaSettings({
                ...ctaSettings,
                [name]: checked,
            });
            return true;
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "landingCtaType") {
            if (!value) {
                setCtaSettings({
                    ...ctaSettings,
                    [name]: value,
                    'ctaContent': '',
                    'landingCtaBtnLabel': ''
                });
                setFiles(""); //  remove cta icon if default theme select
                document.getElementById("cta_settings").innerHTML = 'Enter CTA text in editor';
            } else {
                setCtaSettings({
                    ...ctaSettings,
                    [name]: value,
                    'ctaContent': ctaSettings.landingCtaType ? ctaSettings.ctaContent : ''
                });
            }
        } else {
            if (name === 'ctaContent') {
                document.getElementById("cta_settings").innerHTML = value;
            }
            setCtaSettings({
                ...ctaSettings,
                [name]: value,
            });
        }
        if ((name === 'landingCtaType' ? value : ctaSettings.landingCtaType) && name === 'landingCtaType' || name === 'ctaContent' || name === 'landingCtaBtnLabel' || name === 'landingPage') {
            let landingCtaType = name === 'landingCtaType' ? value : ctaSettings.landingCtaType;
            let landingCtaBtnLabel = name === 'landingCtaBtnLabel' ? value : ctaSettings.landingCtaBtnLabel;
            let ctaContent = name === 'ctaContent' ? value : ctaSettings.ctaContent;

            let ctaDisplayContent = '<div class="customizedCTA_' + landingCtaType + '">';
            ctaDisplayContent += files ? '<img src="' + files + '" class="customizedCTAImg_' + landingCtaType + '"/>' : '';
            ctaDisplayContent += ctaContent ? '<span class="customizedCTATxt_' + landingCtaType + '">' + ctaContent + '</span>' : '';
            ctaDisplayContent += landingCtaBtnLabel ? '<button class="customizedCTABtn_' + landingCtaType + '">' + landingCtaBtnLabel + '</button></div>' : '';

            document.getElementById("cta_settings").innerHTML = ctaDisplayContent;
            document.getElementById("cta_settings").classList = '';
        } else {
            document.getElementById("cta_settings").classList = 'cta_button';
        }
    };

    function pushEditorValue(value) {
        let ctaElement = document.getElementById("cta_settings");
        ctaElement.innerHTML = value;
        setCtaSettings({ ...ctaSettings, ["ctaContent"]: value });
    }

    async function submitForm() {
        if (ctaSettings.landingPage.startsWith("http") && validator.isURL(ctaSettings.landingPage)) {
            if (!ctaSettings.ctaContent) {
                alert("Please fill Click to action text.");
                return;
            }
        } else {
            alert("Please provide a valid url.");
            return;
        }
        const ctaElement = document.getElementById("cta_settings");
        const draggableProps = ctaSettings.draggableProps ? JSON.parse(ctaSettings.draggableProps) : null

        ctaSettings.draggableProps = JSON.stringify({
            height: ctaElement.offsetHeight,
            width: ctaElement.offsetWidth,
            cancel: "div",
            grid: [1, 1],
            defaultPosition: draggableProps ?
                props.deltaPosition.x >= 0 || props.deltaPosition.y >= 0 ?
                    props.deltaPosition
                    : draggableProps.defaultPosition.x >= 0 || draggableProps.defaultPosition.y >= 0 ?
                        draggableProps.defaultPosition
                        : { x: 200, y: 200 }
                : { x: 200, y: 200 }
        });

        // set value and correction
        if (files) {
            ctaSettings.landingCtaIcon = files;
        }
        ctaSettings.ctaStatus = ctaSettings.ctaStatus ? 1 : 2;
        debugger

        try {
            const response = await httpService.PUT("/private/save/update/cta/settings", videoId, ctaSettings)
            if (response.data.status === "SUCCESS") {
                const ctaSettingsObj = response.data.object;
                console.log(ctaSettingsObj);
                alert("Save Succcessfully");
                cancelForm();
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    function cancelForm() {
        window.location.reload(true);
    }

    const getMainVideoById = async (videoId) => {
        try {
            const response = await httpService.GET_BY_ID("/private/main-video", videoId);

            if (response.data.status === "SUCCESS") {
                const mainVideoObject = response.data.object.mainVideo;
                console.log(mainVideoObject);

                const makeCtaSettingsObj = {
                    landingPage: mainVideoObject.landingPage,
                    ctaContent: mainVideoObject.ctaContent,
                    draggableProps: mainVideoObject.draggableProps,
                    landingCtaType: mainVideoObject.landingCtaType || '',
                    landingCtaBtnLabel: mainVideoObject.landingCtaBtnLabel || '',
                    ctaStatus: mainVideoObject.ctaStatus === 1 ? true : false
                }

                const draggablePropsObj = JSON.parse(mainVideoObject.draggableProps);
                const ctaElement = document.getElementById("cta_settings");
                const ctaContainer = document.getElementsByClassName("draggable_child_container")[0];
                if (makeCtaSettingsObj.landingCtaType) {
                    let ctaDisplayContent = '<div class="customizedCTA_' + mainVideoObject.landingCtaType + '">';
                    ctaDisplayContent += files ? '<img src="' + files + '" class="customizedCTAImg_' + mainVideoObject.landingCtaType + '"/>' : '';
                    ctaDisplayContent += mainVideoObject.ctaContent ? '<span class="customizedCTATxt_' + mainVideoObject.landingCtaType + '">' + mainVideoObject.ctaContent + '</span>' : '';
                    ctaDisplayContent += mainVideoObject.landingCtaBtnLabel ? '<button class="customizedCTABtn_' + mainVideoObject.landingCtaType + '">' + mainVideoObject.landingCtaBtnLabel + '</button></div>' : '';
                    document.getElementById("cta_settings").innerHTML = ctaDisplayContent;
                    document.getElementById("cta_settings").classList = '';
                } else {
                    ctaElement.innerHTML = mainVideoObject.ctaContent ? mainVideoObject.ctaContent : "Enter your text...";
                }

                ctaContainer.style.transform = `translate(${draggablePropsObj.defaultPosition.x}px, ${draggablePropsObj.defaultPosition.y}px)`;
                if (mainVideoObject.landingCtaIcon) {
                    setFiles(mainVideoObject.landingCtaIcon);
                }
                setCtaSettings(makeCtaSettingsObj);
            } else {
                console.log("not found video id and data");
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    async function onChangeUploadedImages(file) {
        const base64PosterImg = await fileProcess.manageUploads(file);
        setFiles(base64PosterImg.base64EncodedImg);
    }

    const onChnagePosterUrl = (e) => {
        setFiles(e.target.value);
    }

    useEffect(() => {
        if (files) {
            let ctaDisplayContent = '<div class="customizedCTA_' + ctaSettings.landingCtaType + '">';
            ctaDisplayContent += files ? '<img src="' + files + '" class="customizedCTAImg_' + ctaSettings.landingCtaType + '"/>' : '';
            ctaDisplayContent += ctaSettings.ctaContent ? '<span class="customizedCTATxt_' + ctaSettings.landingCtaType + '">' + ctaSettings.ctaContent + '</span>' : '';
            ctaDisplayContent += ctaSettings.landingCtaBtnLabel ? '<button class="customizedCTABtn_' + ctaSettings.landingCtaType + '">' + ctaSettings.landingCtaBtnLabel + '</button></div>' : '';

            document.getElementById("cta_settings").innerHTML = ctaDisplayContent;
            document.getElementById("cta_settings").classList = '';
        }
    }, [files]);

    useEffect(() => {
        var player = videojs('my-video');
        setPlayer(player);
        if (props.isEdit) {
            setContentMediaId(props.contentId);
            setVideoId(props.videoId);
            getMainVideoById(props.videoId);
        }
    }, []);

    return (
        <React.Fragment>
            {loader ? <Loader /> : null}
            <div id="edit_modal">
                <div className="edit_modal_inner" style={{ display: "flex", alignItems: "center", flexFlow: "column" }}>
                    <div className="edit_modal_title">
                        <h2>CTA Setting</h2>
                    </div>
                    <div className="pauseFlex">
                        <div className="edit_checkbox">
                            <input type="checkbox" onChange={handleInputChangeCheckbox} checked={ctaSettings.ctaStatus} name="ctaStatus" />
                            <label>Enable / Disable</label>
                        </div>
                    </div>
                    <div className="playerCTA">
                        <label>Landing Page URL</label>
                        <input type="text" id="landing_page" name="landingPage" className="config_poster_inp" placeholder="Enter Landing Page URL"
                            value={ctaSettings.landingPage} onChange={handleInputChange} />
                    </div>
                    <div className="playerCTA">
                        <label>Predefined CTA Design</label>
                        <select onChange={handleInputChange} name="landingCtaType" value={ctaSettings.landingCtaType} className="config_poster_inp">
                            <option value="">-- Please Select --</option>
                            <option value={1}>Design 1</option>
                            <option value={2}>Design 2</option>
                        </select>
                    </div>
                    {
                        !ctaSettings.landingCtaType ?
                            <div>
                                <label className="CTAlabel">Click to Action Text</label>
                                <TextEditor width={450} height={250} content={ctaSettings.ctaContent} pushEditorValue={pushEditorValue} style={{ margin: "0 auto" }} isCTA={true} />
                            </div>
                            :
                            <div className="playerCTA">
                                <label>Click to Action Text</label>
                                <input type="text" name="ctaContent" placeholder="Enter CTA text" className="config_poster_inp"
                                    value={ctaSettings.ctaContent} onChange={handleInputChange} />
                            </div>
                    }
                    {
                        ctaSettings.landingCtaType ?
                            <div className="playerCTA">
                                <label>CTA Button Label ( max. 12 characters )</label>
                                <input maxLength="12" type="text" placeholder="Enter CTA button label" name="landingCtaBtnLabel" className="config_poster_inp"
                                    value={ctaSettings.landingCtaBtnLabel} onChange={handleInputChange} />
                            </div>
                            : null
                    }
                    {
                        ctaSettings.landingCtaType ?
                            <div className="playerCTA">
                                <label>CTA Icon</label>
                                <Tabs defaultActiveKey={`enter_url`} id="video_tabs" className="mb-3 tabs_news">
                                    <Tab eventKey={`upload_new_image}`} title={`Upload Icon Image`}>
                                        <FileUploader multiple={false} handleChange={onChangeUploadedImages} name="config_poster-files" id="config_poster-files" types={fileUtils.getFileTypes("image")} />
                                        <p>{files ? <span>file selected.</span> : "select Icon image for upload."}</p>
                                    </Tab>
                                    <Tab eventKey="enter_url" title="Enter URL">
                                        <div className="enter_url">
                                            <input onChange={onChnagePosterUrl} value={files} name="posterImagePath" type="text" id="poster_img" className="config_poster_inp" placeholder="Enter Image Url" />
                                        </div>
                                    </Tab>
                                </Tabs>
                                <div className="pb-4">
                                    {/* https://storage.googleapis.com/innovative-video/add_poster_image_dir/ */}
                                    {files ?
                                        <img className="poster_image_view" src={files} width="100" alt="poster_image_view" />
                                        : <img width="100" src={"https://storage.googleapis.com/innovative-video/add_poster_image_dir/AOvTVQf3_20-01-2023_1674192406481.jpeg"} />
                                    }
                                </div>
                            </div>
                            : null
                    }
                    <div className="buttons_save">
                        <button className="btn_save" onClick={submitForm} style={{ marginTop: "0" }}>Save</button>
                        <button className="btn_cancel" onClick={cancelForm}>Cancel</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}


export default CtaSettings;