import React from "react";
import jwtDecode from "jwt-decode";

export const isSessionExpire = () => {

    let decodedToken = jwtDecode(sessionStorage.getItem("token"));
    let currentDate = new Date();
    const isExpired = decodedToken.exp * 1000 < currentDate.getTime()
        ? sessionStorage.clear()
        : "still token is valid";
    // debugger
    if (isExpired == undefined) {
        console.log("session expired!!! please re-login first...");
        window.location.reload(true);
        // window.location.href = "/"
    } else {

    }
}


const ManageSession = () => {
    return (
        <></>
    )
}

export default ManageSession;