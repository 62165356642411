import { Fragment, useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../../../Common/loader/loder";
import { Badge, Button, Col, Row, Table } from "react-bootstrap";
import Select from 'react-select';
import httpService from "../../../../Common/httpServices/http.service";
import { isSessionExpire } from "../../../authentication/jwt/manageSession";

const TemplateInteraction = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { videoId, templateId } = location.state;

    const [loader, setLoader] = useState(false);
    const [interactionList, setInteractionList] = useState([]);
    const [videoTemplate, setVideoTemplate] = useState(null);
    const [options, setOptions] = useState();
    const [formInfo, setFormInfo] = useState([]);
    // const [cbox, setCbox] = useState(false);


    const getInteractionListById = async () => {
        let response = null;
        debugger
        try {
            response = await httpService.GET_BY_ID("/private/video-interaction", videoId);
            if (response.data.status === "SUCCESS") {
                const data = response.data.object.videoInteractionList;
                setInteractionList(data);
                return data;
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const getVideoTemplateById = async () => {
        let interactionData = await getInteractionListById();
        let response = null;
debugger
        try {
            response = await httpService.GET_BY_ID("/private/video/template", templateId);
            if (response.data.status === "SUCCESS") {
                const templateData = response.data.object;
                console.log(templateData);

                for (let i = 0; i < templateData.templateVideoMacros.length; i++) {
                    const e1 = templateData.templateVideoMacros[i];

                    for (let j = 0; j < interactionData.length; j++) {
                        const e2 = interactionData[j];
                        if (e1.macro === e2.interactionType && Number(e1.templateId) === Number(e2.promptId)) {
                            e1["virtulField"] = e2.id;
                            // e1.interactionId = e2.id;
                            break;
                        }
                    }
                }

                setVideoTemplate(templateData);
                return templateData;
            }
        } catch (err) {
            isSessionExpire();
        }
    }

    const saveTempInterations = async () => {

        if (formInfo.length > 0) {
            try {
                const response = await httpService.PUT_WITHOUT_ID("/private/video-interaction/update/template", formInfo);
                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    alert("Saved Successfully");
                    getVideoTemplateById();
                    redirectTo();
                }
            } catch (err) {
                isSessionExpire();
            }
        } else {
            alert("please select video-interaction");
        }
    }

    const redirectTo = () => {
        window.location.href = `/player/${videoId}&template=1`;
    }

    const onChangeHandler = (e, object) => {
        let isChecked = e.target.checked;


        if (isChecked) {
            let interactionObject = {
                interactionType: object.macro,
                isCustom: 1,
                promptType: "template",
                promptId: object.templateId,
                videoId: Number(videoId)
            }
            setFormInfo([...formInfo, interactionObject])
        } else {
            for (let i = 0; i < formInfo.length; i++) {
                const element = formInfo[i];
                if (element.interactionType === object.macro && Number(element.promptId) === Number(object.templateId)) {
                    formInfo.splice(i, 1);
                }
            }
        }
    }

    const deleteInteraction = async (e, object) => {

        let status = window.confirm("Do you want to delete this template interaction ?");
        if (status === true) {
            try {
                const response = await httpService.DELETE_BY_ID(`/private/video-interaction/delete/${object.virtulField}`, videoId);
                if (response.data.status === "SUCCESS") {
                    console.log(response);
                    alert("Deleted Successfully");
                    getVideoTemplateById();
                    // redirectTo();
                }
            } catch (err) {
                isSessionExpire();
            }
        }

    }

    useEffect(() => {
        getVideoTemplateById();
        // getInteractionListById();
    }, [])

    console.log(formInfo);
    console.log(interactionList);

    return (
        <Fragment>
            {loader ? <Loader /> : null}
            <Container>
                <div className="vd_config">
                    <Row style={{ flexFlow: "row", justifyContent: "space-between" }}>
                        <Col md={12} style={{ width: "auto" }}> <h2>Template Interaction <Badge bg="primary">{videoId}</Badge></h2> </Col>
                        {/* <Col md={12} style={{ width: "auto" }}> <h2 >Video id : {videoId}</h2> </Col> */}
                    </Row>
                    <div className="template_interaction_div">
                        <Table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>S.No</th>
                                    <th>Title</th>
                                    <th>Description</th>
                                    <th>Macro Name</th>
                                    <th>Interaction Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    videoTemplate ?
                                        videoTemplate.templateVideoMacros.map((object, index) => {
                                            if(object.macro.startsWith("tr-gen"))
                                            return (
                                                <Fragment key={index}>
                                                    <tr>
                                                        <td style={{ cursor: "pointer" }}>
                                                            {
                                                                !object.virtulField ?
                                                                    <input id={`temp_checkbox_${object.id}`} type="checkbox" onChange={(e) => onChangeHandler(e, object)} />
                                                                    : <img src="\assets\remove.png" width={20} alt="remove_icon" onClick={(e) => deleteInteraction(e, object)} />
                                                            }
                                                        </td>
                                                        <td>{index + 1}</td>
                                                        <td>{object.label}</td>
                                                        <td>{object.macroDescription}</td>
                                                        <td>{object.macro}</td>
                                                        <td>{object.virtulField}</td>
                                                    </tr>
                                                </Fragment>
                                            );

                                        })
                                        : null
                                }
                            </tbody>
                        </Table>
                        <Row>
                            <Col md={12}>
                                <div className="col-md-12">
                                    <button className="btn_save" onClick={saveTempInterations}>Save</button>
                                    <button className="btn_cancel" onClick={redirectTo}>
                                        Back
                                    </button>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </Container>
        </Fragment >
    )
}

export default TemplateInteraction;
