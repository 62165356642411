import React, { Fragment, useEffect, useState } from "react";
import { Button, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import VariableHtml from "./variableHtml";

const ShowTemplateVariables = (props) => {
    const [mecroData, setMecroData] = useState(props.templateInfo.templateVideoMacros);
    // const [groupId, setGroupId] = useState(props.groupId);

    const filterMacroData = (e, id) => {
        const filterValue = id ? id : e.target.value;
        let data = props.templateInfo.templateVideoMacros;
        let filterData = [];

        if (filterValue) {
            for (let i = 0; i < data.length; i++) {
                const macro = data[i];
                // if (macro.macro.match(filterValue) || macro.groupId == filterValue) {
                if (macro.groupId == filterValue) {
                    filterData.push(macro);
                } else if (macro.macro === filterValue) {
                    filterData.push(macro);
                }
            }
            setMecroData(filterData);
            props.setGroupId(filterValue);
        } else {
            setMecroData(data);
            props.setGroupId(filterValue);
        }
    }

    function copyText(e, value) {
        navigator.clipboard.writeText(value);
        alert("Copied The Text : " + value);
    }

    useEffect(() => {
        setMecroData(props.templateInfo.templateVideoMacros);
        if (props.groupId) {
            filterMacroData(undefined, props.groupId);
        }
    }, [props.groupId, props.templateInfo])

    return (
        <>
            <Col>
                <input type="text" onChange={filterMacroData} value={props.groupId} placeholder="Search By GroupId / Macro" />
            </Col>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Group Id</th>
                        <th>Type / Code</th>
                        <th>Default Value</th>
                        <th>New Value</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        mecroData.map((object, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td>{object.groupId}</td>
                                        <td>
                                            <Col>
                                                <input disabled name='varCode' value={object.macro} onChange={(event) => { props.handleInputChange('varCode', event, index) }}></input>
                                            </Col>
                                            <Col>
                                                <select disabled name='varType' value={object.macroType} onChange={(event) => { props.handleInputChange('varType', event, index) }}>
                                                    <option value="0">--Select an option--</option>
                                                    <option value="1">Image</option>
                                                    <option value="2">Link</option>
                                                    <option value="3">color</option>
                                                    <option value="4">Label</option>
                                                </select>
                                            </Col>
                                        </td>
                                        <td>
                                            <Row>
                                                {/* Image Section */}
                                                {
                                                    object.macroType == '1' &&
                                                    <Col>
                                                        <div><strong>Default Value</strong></div>
                                                        <img name='label' src={object.label} onClick={() => props.handleShowImage(object.label)} height="40px" width="40px"></img>
                                                    </Col>
                                                }
                                                {/* Link Section */}
                                                {
                                                    object.macroType == '2' &&
                                                    <Col >
                                                        <div><strong>Default Value</strong></div>
                                                        <textarea disabled name='label' title={object.label} value={object.label} onChange={(event) => { props.handleInputChange('label', event, index) }}></textarea>
                                                    </Col>
                                                }
                                                {/* Color Section */}
                                                {
                                                    object.macroType == '3' &&
                                                    <Col >
                                                        <div><strong>Default Value</strong></div>
                                                        <input type="color" disabled name='label' value={object.label} onChange={(event) => { props.handleInputChange('label', event, index) }}></input>
                                                    </Col>
                                                }
                                                {/* Label / Text Section */}
                                                {
                                                    object.macroType == '4' &&
                                                    <Col>
                                                        <div><strong>Default Value</strong></div>
                                                        <textarea disabled name='label' value={object.label} onChange={(event) => { props.handleInputChange('label', event, index) }}></textarea>
                                                    </Col>
                                                }

                                                <Col>
                                                    <button onClick={(e) => copyText(e, object.label)}>Copy</button>
                                                </Col>                                            </Row>
                                        </td>
                                        <td>
                                            <Row className="mt-3">
                                                {/* Image Section */}
                                                {
                                                    object.macroType == '1' &&
                                                    <>
                                                        <Col >
                                                            <div><strong>New Value</strong></div>
                                                            <img name='newLabel' src={object.newLabel} onClick={() => props.handleShowImage(object.newLabel)} height="40px" width="40px"></img>
                                                        </Col>
                                                        <Col>
                                                            <button size="sm" className="mt-2" onClick={(e) => props.handleShow(e, index, object.macro)}>Modify</button>
                                                        </Col>
                                                    </>
                                                }
                                                {/* Link Section */}
                                                {
                                                    object.macroType == '2' &&
                                                    <Col >
                                                        <div><strong>New Value</strong></div>
                                                        <textarea name='newLabel' title={object.newLabel} value={object.newLabel} onChange={(event) => { props.handleInputChange('newLabel', event, index, object.macro) }}></textarea>
                                                    </Col>
                                                }
                                                {/* Color Section */}
                                                {
                                                    object.macroType == '3' &&
                                                    <Col >
                                                        <div><strong>New Value</strong></div>
                                                        <input type="color" name='newLabel' value={object.newLabel} onChange={(event) => { props.handleInputChange('newLabel', event, index, object.macro) }}></input>
                                                    </Col>
                                                }
                                                {/* Label / Text Section */}
                                                {
                                                    object.macroType == '4' &&
                                                    <Col >
                                                        <div><strong>New Value</strong></div>
                                                        <textarea name='newLabel' value={object.newLabel} onChange={(event) => { props.handleInputChange('newLabel', event, index, object.macro) }}></textarea>
                                                    </Col>
                                                }
                                            </Row>
                                        </td>
                                        <td>
                                            {
                                                object.macroDescription ?
                                                    <p>
                                                        {object.macroDescription}
                                                    </p>
                                                    : null
                                            }
                                        </td>
                                    </tr>
                                </React.Fragment>
                            )
                        })
                    }

                </tbody>
            </Table>
        </>

    );
}

export default ShowTemplateVariables;